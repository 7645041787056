@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {
  /*
  共通
  -------------------------------------*/
  #Main{
    h3.enTitle{
      margin:93px 0 20px;
      text-align:center;
      font-size:18px;
      color:#888;
      font-weight:700;
      letter-spacing: 3.2px;
      >span{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size:36px;
        color:#222;
        display:block;
        line-height:1;
        margin-bottom:10px;
      }
    }
    .linkBtn{
      a{
        display:block;
        width:100%;
        height:100%;
        text-align:center;
        border:2px solid #222;
        font-size:14px;
        font-weight:700;
        color:#222;
        background-color:transparent;
        background-image:url(../img/arrow02.png);
        background-repeat:no-repeat;
        background-position:center right 18px;
        background-size:10px auto;
        text-decoration:none;
        &:hover{
          background-color:#222;
          background-image:url(../img/arrow01.png);
          color:#fff;
        }
      }
      li{
        &.mail{
          a{
            border:2px solid #8e6420;
            background-image: url(../img/arrow06.png);
            color:#8e6420;
            &:hover{
              background-color:#8e6420;
            }
          }
        }
        &.line{
          a{
            border:2px solid #178d17;
            background-image: url(../img/arrow07.png);
            color:#178d17;
            &:hover{
              background-color:#178d17;
            }
          }
        }
        &.mail,&.line{
          a{
            &:hover{
              background-image: url(../img/arrow01.png);
              color:#fff;
            }
          }
        }
      }
    }
  }

  /*
  トップページ
  -------------------------------------*/

  #Page.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          #TopImg {
            min-width: 1000px;
            height: 640px;
            background: url(../img/img03.jpg) no-repeat center center;
            background-size: cover;
            .innerBasic {
              padding-top: 144px;
              p {
                text-align: center;
                &:first-of-type {
                  font-size: 18px;
                  line-height: 18px;
                  font-weight: 700;
                  color: #ffffff;
                  letter-spacing: 1.5px;
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: -15px;
                    left: 380px;
                    display: block;
                    width: 240px;
                    height: 3px;
                    background: #fff;
                  }
                }
                &:nth-of-type(2) {
                  font-size: 20px;
                  color: #ffffff;
                  letter-spacing: 6px;
                  margin-left: 6px;
                }
              }
              h2 {
                font-size: 40px;
                line-height: 40px;
                font-weight: 700;
                margin: 59px 0 15px 9px;
                letter-spacing: 12px;
                background: linear-gradient(to right, #d9b883 0%,#f9efe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
              }
              dl{
                margin:65px auto 0;
                border:2px solid #2a2928;
                width:470px;
                height:50px;
                font-size:0;
                letter-spacing:normal;
                background:rgba(255,255,255,0.9);
                dt{
                  width:90px;
                  display:inline-block;
                  vertical-align:middle;
                  height:100%;
                  font-size:14px;
                  font-weight:700;
                  color:#fff;
                  background:#2f2e2d;
                  line-height:46px;
                  padding-left:10px;
                  position:relative;
                  &:after{
                    content:"";
                    position:absolute;
                    top:0;
                    right:-15px;
                    width:0;
                    height:0;
                    border-style: solid;
                    border-width: 23px 0 23px 15px;
                    border-color: transparent transparent transparent #2f2e2d;
                  }
                }
                dd.serch_dd{
                  display:inline-block;
                  vertical-align:middle;
                  width:376px;
                  height:100%;
                  line-height:46px;
                  font-size:0;
                  letter-spacing:normal;
                  padding-left:15px;
                  form{
                    width:293px;
                    height:100%;
                    display:inline-block;
                    vertical-align:middle;
                    position:relative;
                    &:after{
                      content:"";
                      position:absolute;
                      top:0;
                      bottom:0;
                      right:0;
                      margin:auto;
                      background-color:#2b2a2a;
                      width:1px;
                      height:26px;
                    }
                    label{
                      width:100%;
                      height:100%;
                      display:block;
                      padding-left:52px;
                      background-image:url(../img/mark_camera.png);
                      background-repeat:no-repeat;
                      background-size:27px auto;
                      background-position:center left 14px;
                      &:hover{
                        cursor:pointer;
                        opacity:0.7;
                      }
                      div{
                        font-size:16px;
                        font-weight:700;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        overflow:hidden;
                      }
                    }
                    input{
                      display:none;
                    }
                  }
                  .mail_send{
                    width:68px;
                    display:inline-block;
                    vertical-align:middle;
                    font-size:14px;
                    font-weight:700;
                    text-align:center;
                    cursor: pointer;
                    &:hover{
                      opacity:0.7;
                    }
                    &:after{
                      content:"";
                      display:inline-block;
                      vertical-align:top;
                      width:9px;
                      height:46px;
                      background:url(../img/arrow02.png) no-repeat center right;
                      background-size:9px auto;
                      margin-left:8px;
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          .innerbasic {
            width: 1000px;
            margin: 0 auto 141px auto;
            h3 {
              font-size: 36px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              text-align: center;
              margin-top: 84px;
              letter-spacing: 3px;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              color: #888888;
              text-align: center;
              margin-bottom: 51px;
              letter-spacing: 3px;
            }
            >div {
              display: table;
              >dl {
                display: table-cell;
                width: 350px;
                padding-right: 50px;
                border-collapse: separate;
                border-spacing: 3px 0;
                >dd {

                  &:first-of-type {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: 'Lato', sans-serif;
                    position: relative;
                    display: inline-block;
                    &:after {
                      content: "";
                      position: absolute;
                      top: 24px;
                      left: -9px;
                      display: inline-block;
                      width: 64px;
                      height: 1px;
                      background-color: black;
                      -webkit-transform: rotate(-60deg);
                      transform: rotate(-45deg);
                    }
                  }
                  &:nth-of-type(2) {
                    font-weight:700;
                    font-size: 14px;
                    font-family: 'Lato', sans-serif;
                    margin-top: 5px;
                    margin-left: 25px;
                    text-transform: uppercase;
                    letter-spacing: 1.4px;
                  }
                  &:nth-of-type(3) {
                    font-size: 16px;
                    line-height: 29px;
                    color: #888888;
                    margin-top: 16px;
                    letter-spacing: .7px;
                    span {
                      display: block;
                    }
                  }
                }
                >dt {
                  font-weight: 700;
                  font-size: 24px;
                  margin-top: 5px;
                  letter-spacing: 2px;
                }
                &:nth-of-type(2) {
                  display:table-cell;
                }
                &:nth-of-type(3) {
                  display: table-cell;
                  width: 300px;
                  padding-right: 0;
                }
              }
            }
            ul {
              margin-top: 51px;
              height: 70px;
              position: relative;
              li {
                text-align: center;
                width: 440px;
                height: 70px;
                margin: auto;
                top:0;
                bottom:0;
                left:0;
                right:0;
                position: absolute;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  border: 3px solid #222222;
                  background: transparent url(../img/arrow04.png) no-repeat center right 27px;
                  background-size: 9px auto;
                  color: #222222;
                  font-size: 16px;
                  line-height: 65px;
                  font-weight: 700;
                  text-decoration: none;
                  letter-spacing: 1.5px;
                  &:hover {
                    background: url(../img/arrow01.png) no-repeat center right 27px;
                    background-color: #222;
                    color: #fff;
                    background-size: 9px auto;
                  }
                }
              }
            }
          }
          #FooterMail {
            display: none;
          }
        }
        #ContBox03 {
          height: 1024px;
          background: url(../img/img17.jpg) no-repeat center center;
          background-size: cover;
          .innerBasic {
            padding-top: 100px;
            p {
              color: #ffffff;
              text-align: center;
              &:first-of-type {
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
                letter-spacing: 2px;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -11px;
                  left: 395px;
                  display: block;
                  width: 210px;
                  height: 3px;
                  background: #fff;
                }
              }
              &:nth-of-type(2) {
                font-size: 18px;
                color: #cccccc;
                font-weight: 700;
                letter-spacing: 4px;
                margin-bottom: 16px;
              }
              &:nth-of-type(3) {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 2px;
                color: #fff;
                span {
                  display: block;
                }
              }
            }
            h3 {
              color: #ffffff;
              text-align: center;
              font-size: 36px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              letter-spacing: 3px;
              margin-top: 37px;
            }
            >dl {
              color: #ffffff;
              margin-top: 26px;
              font-size: 0;
              letter-spacing: normal;
              >dt {
                display: inline-block;
                background-color: rgba(34,34,34,0.9);
                width: 270px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: relative;
                letter-spacing: 1.5px;
                font-size: 16px;
                color: #fff;
                padding-left: 17px;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 22px;
                  height: 50px;
                  background: url(../img/icon_12.png) no-repeat;
                  background-position: right top;
                  background-size: 22px auto;
                  position: absolute;
                  top: 0;
                  right: -22px;
                }
              }
              >dd {
                width: 700px;
                display: inline-block;
                margin-left: 25px;
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
                font-size: 16px;
                vertical-align: top;
                div {
                  &:first-of-type {
                    width: 305px;
                    display: inline-block;
                    padding-left: 27px;
                    letter-spacing: 2px;
                    line-height: 47px;
                    position: relative;
                    &:after {
                      content: "→";
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      top: 0px;
                      right: -10px;
                    }
                  }
                  &:nth-of-type(2) {
                    width: 355px;
                    font-weight: 700;
                    display: inline-block;
                    margin-left: 25px;
                    letter-spacing: 1.5px;
                    line-height: 47px;
                    position: relative;
                    span {
                      font-size: 24px;
                      font-weight: 700;
                      letter-spacing: 4px;
                      margin: auto 0 auto 13px;
                      position: absolute;
                      top:0;
                      bottom: 0;
                      span {
                        font-size: 16px;
                        font-weight: 700;
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
            >ul {
              font-size: 0;
              letter-spacing: normal;
              margin-top: 58px;
              >li {
                width: 320px;
                height: 320px;
                background-color: rgba(255,255,255,0.95);
                display: inline-block;
                position: relative;
                >dl {
                  color: #222;
                  height: 75px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                  >dt {
                    display: inline-block;
                    height: 35px;
                    font-size: 14px;
                    line-height: 35px;
                    letter-spacing: 1.3px;
                    color: #fff;
                    text-align:center;
                    background-color: rgba(34,34,34,0.9);
                    padding: 0 10px;
                  }
                  >dd {
                    height: 40px;
                    font-size: 14px;
                    line-height: 40px;
                    padding-left: 10px;
                    letter-spacing: 1px;
                    span {
                      font-weight: 700;
                    }
                  }
                }
                figure {  //鞄画像全体設定
                  height: 280px;
                  width: 280px;
                  margin: 0 auto;
                  position: relative;
                  img {
                    position: absolute;
                    margin: auto;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                  }
                }
                >a {
                  width: 100%;
                  height: 100%;
                  display:block;
                  &:hover{
                    opacity:0.7;
                    img{
                      opacity:0.7;
                    }
                  }
                }
                &:first-of-type {   //シャネル　ミニマトラッセ
                  margin-right: 20px;
                }
                &:nth-of-type(2) {  //エルメス　ケリー
                  margin-right: 20px;
                }
                &:nth-of-type(3) {  //ルイヴィトン　ネヴァーフル
                }
              }
              &:nth-of-type(2) {  //買い取り実績はこちらボタンのUL
                height: 70px;
                position: relative;
                margin-top: 60px;
                li {
                  background-color: transparent;
                  border: 3px solid #fff;
                  width: 440px;
                  height: 70px;
                  position: absolute;
                  margin: auto;
                  top:0;
                  bottom:0;
                  left:0;
                  right:0;
                  a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: transparent url(../img/arrow01.png) no-repeat center right 27px;
                    background-size: 9px auto;
                    color: #fff;
                    font-size: 16px;
                    line-height: 65px;
                    font-weight: 700;
                    text-decoration: none;
                    letter-spacing: 1.5px;
                    text-align: center;
                    &:hover {
                      background-color: #fff;
                      color: #222;
                      background: url(../img/arrow04.png) no-repeat center right 27px;
                      background-size: 9px auto;
                      opacity:1;
                    }
                    span {

                    }
                  }
                }

              }
            }
          }
        }
        #ContBox04 {
          margin-bottom: 122px;
          .customerWrap {
            background-color: #eeeeee;
            padding-top: 92px;
            padding-bottom: 121px;
            .innerbasic {
              width: 1000px;
              margin: 0 auto;
              font-size: 0;
              letter-spacing: normal;
              >p {
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 1.5px;
                margin-bottom: 28px;
                position: relative;
                &:first-of-type:after {
                  content: "";
                  position: absolute;
                  bottom: -3px;
                  left: 390px;
                  display: block;
                  width: 220px;
                  height: 3px;
                  background: #222;
                }
                &:nth-of-type(2) {
                  font-size: 18px;
                  color: #888888;
                  font-weight: 700;
                  letter-spacing: 3px;
                  margin-bottom: 53px;
                }
              }
              h3 {
                text-align: center;
                font-weight:700;
                font-size: 36px;
                font-family: 'Lato', sans-serif;
                letter-spacing: 3px;
                span {

                }
              }
              >div {
                width: 490px;
                background-color: #fff;
                padding: 30px;
                display: inline-block;
                margin-bottom: 20px;
                figure {
                  width: 180px;
                  display: inline-block;
                  img {
                    width: 150px;
                  }
                }
                >dl {
                  width: 240px;
                  display: inline-block;
                  border-left: 1px solid #dcdcdc;
                  vertical-align: top;
                  margin-top: 10px;
                  padding-top: 4px;
                  padding-left: 30px;
                  &:after {

                    content: ".";
                    display: block;
                    height: 0;
                    font-size:0;
                    clear: both;
                    visibility:hidden;
                  }
                  dt {
                    font-size: 14px;
                    line-height: 14px;
                    color: #888888;
                    width: 64px;
                    margin-top: 10px;
                    &:first-of-type {
                      width: 64px;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 20px;
                      color: #fff;
                      background-color: #b9b9b9;
                      text-align: center;
                      margin-top: 0;
                    }
                  }
                  dd {
                    font-size: 14px;
                    line-height: 14px;
                    float: right;
                    margin-top: -14px;
                    width: 145px;
                    padding-left: 16px;
                    letter-spacing: 1px;
                  }
                }
                >p {
                  text-align: left;
                  font-size: 16px;
                  line-height: 27px;
                  margin-top: 20px;
                  margin-bottom:0;
                  letter-spacing: 1px;
                }
                &:first-of-type {
                  margin-right: 20px;
                }
                &:nth-of-type(3) {
                  margin-right: 20px;
                }
              }
              ul {
                margin-top: 40px;
                height: 70px;
                position: relative;
                li {
                  text-align: center;
                  width: 440px;
                  height: 70px;
                  margin: auto;
                  top:0;
                  bottom:0;
                  left:0;
                  right:0;
                  position: absolute;
                  a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 3px solid #222222;
                    background: transparent url(../img/arrow04.png) no-repeat center right 27px;
                    background-size: 9px auto;
                    color: #222222;
                    font-size: 16px;
                    line-height: 65px;
                    font-weight: 700;
                    text-decoration: none;
                    letter-spacing: 1.5px;
                    &:hover {
                    background: transparent url(../img/arrow01.png) no-repeat center right 27px;
                    background-size: 9px auto;
                    color: #fff;
                    background-color: #222;
                    }
                  }
                }
              }
            }
          }
          .shopWrap {
            width: 100%;
            height: 510px;
            background-color: #d9b883;
            padding-top: 10px;
            padding-bottom: 10px;
            >div {
              height: 490px;
              background: url(../img/img18.jpg) no-repeat center center;
              background-size: cover;
              position: relative;
              .innerbasic {
                background: url(../img/img_shop.png) no-repeat right bottom;
                background-size: 352px auto;
                width: 1000px;
                height: 330px;
                position: absolute;
                margin: auto;
                top:0;
                bottom:0;
                left:0;
                right:0;
                background-color: rgba(255,255,255,0.95);
                font-size: 0;
                letter-spacing: normal;
                h4 {
                  width: 170px;
                  height: 40px;
                  line-height: 40px;
                  background-color: #222222;
                  font-weight: 700;
                  font-size: 16px;
                  color: #fff;
                  margin: 0 auto;
                  text-align: center;
                  letter-spacing: 1.5px;
                }
                >div {
                  width: 440px;
                  display: inline-block;
                  margin-top: 20px;
                  margin-left: 60px;
                  margin-bottom: 30px;
                  >dl {
                    >dd {
                      font-weight: 700;
                      &:first-of-type {
                        font-size: 16px;
                        line-height: 16px;
                        margin-top: 31px;
                        margin-bottom: 33px;
                        letter-spacing: 1.5px;
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          bottom: -11px;
                          left: 0px;
                          display: block;
                          width: 260px;
                          height: 3px;
                          background: #222;
                        }
                      }
                      &:nth-of-type(2) {
                        font-size: 36px;
                        line-height: 36px;
                        font-family: 'Lato', sans-serif;
                        margin-bottom: 20px;
                        letter-spacing: 3px;
                        span {
                          margin-left: -22px;
                          font-weight: 400;
                          &:nth-of-type(2) {
                            margin-left: 4px;
                          }
                        }
                      }
                      &:nth-of-type(3) {
                        font-size: 14px;
                        text-align: center;
                        width: 160px;
                        height: 44px;
                        a {
                          display: block;
                          width: 100%;
                          height: 100%;
                          border: 2px solid #222222;
                          background: transparent url(../img/arrow04.png) no-repeat top 13px right 15px;
                          background-size: 9px auto;
                          color: #222222;
                          font-size: 14px;
                          line-height: 44px;
                          font-weight: 700;
                          text-decoration: none;
                          letter-spacing: 1.5px;
                          padding-right: 20px;
                          position: relative;
                          span {
                            position: absolute;
                            top: -2px;
                            left: 18px;
                          }
                          &:hover {
                          background: transparent url(../img/arrow01.png) no-repeat top 13px right 15px;
                          background-size: 9px auto;
                          color: #fff;
                          background-color: #222;
                          }
                        }
                      }
                    }
                    dt {
                      font-size: 24px;
                      line-height: 24px;
                      font-weight: 700;
                      margin-bottom: 9px;
                      letter-spacing: 2px;
                    }
                  }
                  &:nth-of-type(2) {
                    width: 440px;
                    display: inline-block;
                    vertical-align: top;
                    border-left: 1px solid #222222;
                    margin-left:0;
                    margin-right: 60px;
                    dl {
                      dd {
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 59px;
                        line-height: 32px;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        letter-spacing: 2px;
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .identification {
            .innerbasic {
              width: 1000px;
              margin: 0 auto;
              h4 {
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                margin-top: 95px;
                margin-bottom: 32px;
                letter-spacing: 2px;
              }
              dl {
                padding: 33px 40px 27px 60px;
                background-color: #eeeeee;
                dt {
                  font-size: 16px;
                  line-height: 16px;
                  margin-bottom: 9px;
                  position: relative;
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    background: url(../img/mark_check.png) no-repeat;
                    background-position: left center;
                    background-size: 21px auto;
                    position: absolute;
                    top: -5px;
                    left: -30px;
                  }
                }
                dd {
                  font-size: 14px;
                  line-height: 14px;
                  letter-spacing: .6px;
                }
              }
              p {
                font-size: 14px;
                line-height: 14px;
                color: #888888;
                padding-left:1.4em;
                text-indent:-1.4em;
                margin-bottom: 0;
                letter-spacing: .8px;
                &:first-of-type {
                  margin-top: 30px;
                }
                &:nth-of-type(2) {
                  margin-top: 15px;
                }
                &:nth-of-type(3) {
                  margin-top: 14px;
                }
                &:nth-of-type(4) {
                  margin-top: 10px;
                  line-height: 23px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    #Page.pageIndex{
      #Container {
        #Main {
          #ContBox01 {
            #TopImg {
              .innerBasic {
                h2 {
                  color: #eaceab;
                  background:transparent;
                  span {
                    color: #eaceab;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  はじめての方へ
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          p {
            font-size: 20px;
            line-height: 38px;
            color: #222222;
            text-align: center;
            font-weight: 700;
            span {
              display: block;
            }
          }
          section.contSubBox {
            width: 100%;
            background-color: #d9b883;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 50px;
            margin-bottom: 74px;
            overflow: hidden;
            min-width: 1000px;
            >div {
              height: 360px;
              background: url("../img/01_main.jpg") no-repeat center center;
              background-size: cover;
              min-width: 1000px;
              position: relative;
              .div_small {
                margin:0 auto;
                position: absolute;
                top: 91px;
                left: 0;
                right:0;
                //transform: translateY(-50%) translateX(-50%);
                p {
                  margin:0;
                  color: #ffffff;
                  letter-spacing:1.9px;
                }
                h4 {
                  margin-top: 31px;
                  text-align: center;
                  font-size: 30px;
                  font-weight: 700;
                  letter-spacing:4.5px;
                  color: #ffffff;
                  span {
                    display: block;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #f5e9d5;
                    letter-spacing:0.8px;
                    background: linear-gradient(to right, #d9b883 0%,#f9efe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    span {
                      display: inline-block;
                    }
                  }
                }
              }
            }

          }
        }
        #ContBox02 {
          .innerBasic {
            .clearfix:after {
              content: ".";
              display: block;
              height: 0;
              font-size:0;
              clear: both;
              visibility:hidden;
            }
            .clearfix {
              display: inline-table;
            }
            .contSubBox02 {
              display: block;
              width: 100%;
              height: auto;
              border-bottom: 1px solid #dcdcdc;
              &:first-of-type {
                border-top: 1px solid #dcdcdc;
              }
              &:last-of-type {
                margin-bottom: 170px;
              }
              .left {
                float: left;
                width: 290px;
                visibility: visible;
                margin-top: 30px;
                dl {
                  font-size: 16px;
                  margin-top: 7px;
                  dt {
                    font-size: 24px;
                    line-height: 24px;
                    margin-top: 8px;
                    letter-spacing: 2px;
                    font-weight: 700;
                    span {
                      display:block;
                      margin-top: 12px;
                    }
                  }
                  dd {
                    &:first-of-type {
                      position: relative;
                      display: inline-block;
                      font-family: 'Lato', sans-serif;
                      font-weight: 700;
                      //padding: 0 45px;
                      &:after {
                        content: '';
                        position: absolute;
                        top: 24px;
                        left: -9px;
                        display: inline-block;
                        width: 64px;
                        height: 1px;
                        background-color: black;
                        -moz-transform: rotate(-60deg);
                        -webkit-transform: rotate(-60deg);
                        -ms-transform: rotate(-60deg);
                        transform: rotate(-45deg);
                      }
                    }
                    &:nth-of-type(2) {
                      text-transform: uppercase;
                      margin-left: 24px;
                      margin-top: 8px;
                      font-size: 14px;
                      font-weight: 700;
                      font-family: 'Lato', sans-serif;
                    }
                  }
                }
              }
              .right {
                float: left;
                width: 700px;
                visibility: visible;
                margin-top: 30px;
                margin-bottom: 30px;
                border-left: 1px solid #dcdcdc;
                p {
                  font-size: 16px;
                  padding-left: 30px;
                  margin-top: 22px;
                  margin-bottom: 22px;
                  letter-spacing: 0.6px;
                  span {
                    display: block;
                  }
                }
              }
            }
            #ContSubBox02 {
              .left dl {
              margin-top: 58px;
              }
              .right {
                p {
                  margin-top: 22px;
                  margin-bottom: 22px;
                }
              }
            }
            #ContSubBox03 {
              .left dl {
              margin-top: 8px;
              }
              .right {
                p {
                  margin-top: 21px;
                  margin-bottom: 21px;
                }
              }
            }
            #ContSubBox04 {
              .left dl {
              margin-top: 22px;
              }
              .right {
                p {
                  margin-top: 21px;
                  margin-bottom: 21px;
                }
              }
            }
            #ContSubBox05 {
              .left dl {
              margin-top: 10px;
              }
              .right {
                p {
                  margin-top: 23px;
                  margin-bottom: 20px;
                }
              }
            }
            #ContSubBox06 {
              .left dl {
              margin-top: 10px;
              }
              .right {
                p {
                  margin-top: 22px;
                  margin-bottom: 25px;
                }
              }
            }
            #ContSubBox06 .right p span {
              color: #888888;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    #PageAbout.pageIndex {
      #Container {
        #Main {
          #ContBox01 {
            section.contSubBox {
              >div {
                .div_small {
                  h4 {
                    span {
                      color: #eaceab;
                      background:transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  査定・買取の方法
  -------------------------------------*/
  #PageService.pageIndex {
    #Main{
      margin-bottom:185px;
      .contBox{
        >div{
          background-color:#eeeeee;
          padding:30px 0;
          text-align:center;
          .innerBasic{
            font-size:0;
            letter-spacing:normal;
          }
          dl{
            display:inline-block;
            vertical-align:top;
            width:490px;
            background-color:#fff;
            padding:53px 0 0;
            height:350px;
            font-size:16px;
            font-weight:700;
            &+dl{
              margin-left:20px;
            }
            dd{
              &:first-of-type{
                font-size:16px;
                border-bottom:3px solid #222;
                line-height:30px;
                display:inline-block;
                padding:0 3px;
                letter-spacing: 1.5px;
              }
              &:last-of-type{
                &.linkBtn{
                  margin:30px auto 0;
                  width:280px;
                  height:50px;
                  line-height:50px;
                }
                ul{
                  font-size:0;
                  letter-spacing:normal;
                  margin-top:30px;
                  li{
                    display:inline-block;
                    vertical-align:top;
                    width:208px;
                    height:50px;
                    line-height:50px;
                    &+li{
                      margin-left:14px;
                    }
                  }
                }
              }
            }
            dt{
              margin-top:33px;
              font-size:24px;
              padding-top:65px;
              background-image:url(../img/mark_mail02.png);
              background-repeat:no-repeat;
              background-position:center top 7px;
              background-size:51px auto;
              letter-spacing: 2px;
            }
          }
        }
      }
      #ContBox01{
        >div{
          dl{
            &:nth-child(2){
              dt{
                background-image:url(../img/mark_box03.png);
                background-position:center top;
                background-size:52px auto;
              }
            }
          }
        }
      }
      #ContBox02{
        >div{
          dl{
            &:nth-child(1){
              dt{
                background-image:url(../img/mark_shop.png);
                background-position:center top;
                background-size:51px auto;
              }
            }
            &:nth-child(2){
              dt{
                background-image:url(../img/mark_car.png);
                background-position:center top 7px;
                background-size:63px auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  買取実績
  -------------------------------------*/
  /*共通パーツ*/
  #PagePurchase_record{
    #MainImg{
      #MainImgInner{
        width: auto;
        h2{
          font-size: 40px;
          font-weight: bold;
          margin: 0;
          padding: 98px 0;
          letter-spacing: 7px;
        }
      }
    }
    #Container{
      .cate{
        margin: auto;
        width: 1000px;
        font-size: 0;
        text-align: center;
        li{
          display: inline-block;
          border: 2px solid #000;
          margin: 0 10px 10px 0;
          text-transform:uppercase;
          font-size: 14px;
          font-weight: bold;
          font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          a{
            display: block;
            padding: 5px 14px;
            &:hover{
              background-color: #000;
              color: #ffffff;
            }
          }
        }
        li.active,li.current-cat,li.current-cat-parent{
          padding: 5px 16px 0;
          border: none;
          a{
            padding: 0 0 5px;
            border-bottom: 2px solid #000;
            &:hover{
              background-color: #fff;
              color: #222222;
            }
          }
        }
      }
      .subCate{
        margin-top: 30px;
        background-color: #eeeeee;
        font-size: 0;
        ul{
          width: 1000px;
          margin: auto;
          text-align: center;
          li{
            margin: 0 20px;
            padding: 11px 0;
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
            //font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            a{
              &:hover{
                opacity: 0.7;
              }
            }
          }
          li.active,li.current-cat{
            border-bottom: 2px solid #000;
            padding: 11px 0 9px;
            //font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            a{
              &:hover{
                opacity: 1;
              }
            }
          }
        }
      }
      #Main ul li figure img{
        max-width: 230px;
        max-height: 230px;
        height:auto;
      }
      #Main{
        #ContBox01{
          #ContSubBox01{
            >ul{
              font-size:0;
              letter-spacing:normal;
              >li{
                display:inline-block;
                vertical-align:top;
                dl{
                  dt{
                    span{
                      &+span{
                        &:before{
                          content:"/ ";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
   }
  }
  /*共通パーツここまで*/
  #PagePurchase_record.pageIndex {
    a{
      text-decoration: none;
    }
    #Container {
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              ul{
                margin-top: 100px;
                &::after{
                  content: "";
                  display: block;
                  clear: both;
                }
                li{
                  width: 300px;
                  margin-right: 50px;
                  margin-bottom: 60px;
                  &:hover{
                    opacity: 0.7;
                  }
                  &:nth-of-type(3n){
                    margin-right: 0;
                  }
                  &:nth-last-of-type(1),
                  &:nth-last-of-type(2),
                  &:nth-last-of-type(3)
                  {
                    margin-bottom: 0;
                  }
                  figure{
                    position: relative;
                    height: 205px;
                    text-align: center;
                    z-index: -1;
                    img{
                          position: absolute;
                          margin: auto;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          top: 0;
                    }
                  }
                  dl{
                    font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                    dt{
                      color: #fff;
                      font-size: 12px;
                      display: inline-block;
                      padding: 5px 10px;
                      margin-bottom: 5px;
                      background-color: rgba(34,34,34,0.9);
                      text-transform:uppercase;
                    }
                    dd{
                        color: #888888;
                        font-size: 14px;
                      }
                  }
                  h4{
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height:28px;
                  }
                }
              }
            }
            #PageNation{
              margin-top: 100px;
              margin-bottom: 190px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin: 0 52px;
                li{
                  margin-right: 37px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    color: #888888;
                    font-size: 16px;
                    text-decoration: underline;
                    &:hover{
                      text-decoration: none;
                    }
                  }
                  span{
                    text-decoration:none;
                  }
                }
                .active span{
                  text-decoration: none;
                  color: #000000;
                }
              }
              >span{
                a{
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  width: 100%;
                  font-size: 14px;
                  &:hover{
                    background-color: #222222;
                    color: #fff;
                  }
                }
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
              .prev.sp_display {
                display: none!important;
              }
            }
          }
        }
      }
    }
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PagePurchase_record.pageCategory {
    a{
      text-decoration: none;
    }
    #Container {
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              h3{
                text-align: center;
                margin-top: 90px;
                span{
                  font-size: 16px;
                  font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  font-weight: bold;
                  border-bottom: 3px solid #000;
                  display: inline-block;
                  padding:0 2px 5px;
                }
              }
              ul{
                margin-top: 100px;
                &::after{
                  content: "";
                  display: block;
                  clear: both;
                }
                li{
                  width: 300px;
                  margin-right: 50px;
                  margin-bottom: 60px;
                  position: relative;
                  &:hover{
                    opacity: 0.7;
                  }
                  &:nth-of-type(3n){
                    margin-right: 0;
                  }
                  &:nth-last-of-type(1),
                  &:nth-last-of-type(2),
                  &:nth-last-of-type(3)
                  {
                    margin-bottom: 0;
                  }
                  figure{
                    position: relative;
                    height: 205px;
                    text-align: center;
                    z-index: -1;
                    img{
                          position: absolute;
                          margin: auto;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          top: 0;
                    }
                  }
                  dl{
                    font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                    dt{
                      background-color: rgba(34,34,34,0.9);
                      text-transform:uppercase;
                      color: #fff;
                      font-size: 12px;
                      display: inline-block;
                      padding: 5px 10px;
                      margin-bottom: 5px;
                    }
                    dd{
                        color: #888888;
                        font-size: 14px;
                      }
                  }
                  h4{
                    margin-top: 5px;
                    font-size: 16px;
                    line-height:28px;
                    font-weight: bold;
                  }
                }
              }
            }
            #PageNation{
              margin-top: 100px;
              margin-bottom: 190px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin: 0 52px;
                li{
                  margin-right: 37px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    color: #888888;
                    font-size: 16px;
                    text-decoration: underline;
                    &:hover{
                      text-decoration: none;
                    }
                  }
                  span{
                    text-decoration:none;
                  }
                }
                .active span{
                  text-decoration: none;
                  color: #000000;
                }
              }
              >span{
                a{
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  width: 100%;
                  font-size: 14px;
                  &:hover{
                    background-color: #222222;
                    color: #fff;
                  }
                }
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
              .prev.sp_display {
                display: none!important;
              }
            }
          }
        }
      }
    }
  }

  /*
  サブカテゴリ
  -------------------------------------*/
  #PagePurchase_record.pageCategory2 {
    a{
      text-decoration: none;
    }
    #Container {
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              h3{
                text-align: center;
                margin-top: 90px;
                span{
                  font-size: 16px;
                  font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  font-weight: bold;
                  border-bottom: 3px solid #000;
                  display: inline-block;
                  padding:0 2px 5px;
                }
              }
              ul{
                margin-top: 100px;
                &::after{
                  content: "";
                  display: block;
                  clear: both;
                }
                li{
                  width: 300px;
                  margin-right: 50px;
                  margin-bottom: 60px;
                  position: relative;
                  &:hover{
                    opacity: 0.7;
                  }
                  &:nth-of-type(3n){
                    margin-right: 0;
                  }
                  &:nth-last-of-type(1),
                  &:nth-last-of-type(2),
                  &:nth-last-of-type(3)
                  {
                    margin-bottom: 0;
                  }
                  figure{
                    position: relative;
                    height: 205px;
                    text-align: center;
                    z-index: -1;
                    img{
                          position: absolute;
                          margin: auto;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          top: 0;
                    }
                  }
                  dl{
                    font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                    dt{
                      background-color: rgba(34,34,34,0.9);
                      text-transform:uppercase;
                      color: #fff;
                      font-size: 12px;
                      display: inline-block;
                      padding: 5px 10px;
                      margin-bottom: 5px;
                    }
                    dd{
                        color: #888888;
                        font-size: 14px;
                      }
                  }
                  h4{
                    margin-top: 5px;
                    font-size: 16px;
                    line-height:28px;
                    font-weight: bold;
                  }
                }
              }
            }
            #PageNation{
              margin-top: 100px;
              margin-bottom: 190px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin: 0 52px;
                li{
                  margin-right: 37px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    color: #888888;
                    font-size: 16px;
                    text-decoration: underline;
                    &:hover{
                      text-decoration: none;
                    }
                  }
                  span{
                    text-decoration:none;
                  }
                }
                .active span{
                  text-decoration: none;
                  color: #000000;
                }
              }
              >span{
                a{
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  width: 100%;
                  font-size: 14px;
                  &:hover{
                    background-color: #222222;
                    color: #fff;
                  }
                }
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
              .prev.sp_display {
                display: none!important;
              }
            }
          }
        }
      }
    }
  }

  /*
  実績詳細
  -------------------------------------*/
  #PagePurchase_record.pageEntry {
    #Container {
      a{
        text-decoration:none;
      }
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              margin-top: 100px;
              padding-bottom: 75px;
              border-bottom: 1px solid #dcdcdc;
              .innerbasic{
                width: 800px;
                margin:auto;
                dl{
                  text-align: center;
                  dt{
                    display: inline-block;
                    background-color: rgba(34,34,34,0.9);
                    text-transform:uppercase;
                    color: #fff;
                    font-size: 12px;
                    margin-bottom: 10px;
                    padding: 6px 8px;
                    span{
                      &+span{
                        &:before{
                          content:"/ ";
                        }
                      }
                    }
                  }
                  dd{
                    font-size: 14px;
                    color: #888888;
                  }
                }
                h4{
                  margin-top: 10px;
                  margin-bottom: 85px;
                  text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                }
                div{
                  p{
                    font-size: 16px;
                    line-height: 32px;
                    margin-bottom: 35px;
                  }
                }
                figure{margin: 50px 0;}
              }
            }
          }
        }
      }

      #PageNation{
              width:392px;
              margin:60px auto 190px;
              text-align: center;
              font-size: 0;
              position:relative;
              span{display:inline-block;}
              span{
                border: 2px solid #222222;
                position:absolute;
                a{
                  display: block;
                  padding: 9px 0;
                  width: 100%;
                  font-size: 14px;
                  &:hover{
                    background-color: #222222;
                    color: #fff;
                  }
                }
              }
              .prev{
                top:0;
                left:0;
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .back{
                border: none;
                margin: 0 70px;
                position:static;
                a{
                  color: #888888;
                  text-decoration: underline;
                  &:hover{
                    text-decoration:none;
                    background-color: #fff;
                   color: #888;
                  }
                }
              }
              .next{
                top:0;
                right:0;
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
              .prev.sp_display {
                display: none!important;
              }
            }
    }
  }

  /*
  お客様の声
  -------------------------------------*/
  #PageCustomer_review.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-bottom: 96px;
            #ContSubBox01 {
              .largBox {
                width: 100%;
                &:first-of-type {
                  margin-top: 116px;
                }
                &:after {
                  content: ".";
                  display: block;
                  height: 0;
                  font-size:0;
                  clear: both;
                  visibility:hidden;
                }
                figure {
                  float: left;
                  img {
                    width: 475px;
                  }
                }
                >div {
                  &:first-of-type {
                    width: 475px;
                    height: 245px;
                    margin-left: 50px;
                    float: left;
                    h3 {
                      //display: table-cell;
                      //vertical-align: middle;
                      width: 80px;
                      height: 30px;
                      line-height: 30px;
                      text-align: center;
                      font-size: 14px;
                      color: #ffffff;
                      background-color: #b9b9b9;
                      margin-bottom: 24px;
                    }
                    dl {
                      &:last-child {
                        border-bottom: 1px solid #dcdcdc;
                        padding-bottom: 13px;
                      }
                      dt {
                        width: 96px;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 16px;
                        color: #b9b9b9;
                      }
                      dd {
                        display: inline-block;
                        color: #222222;
                        font-size: 16px;
                        line-height: 16px;
                        margin-bottom: 17px;
                      }
                    }
                  }
                }
              }
              .smallBox {
                // float: right;
                width: 695px;
                position: relative;
                top: -71px;
                left: 304px;
                padding-left: 32px;
                padding-top: 11px;
                // right: 0;
                margin-bottom: 20px;
                z-index: 9998;
                background-color: #ffffff;
                p {
                  font-size: 16px;
                  line-height: 32px;
                  margin-top: 11px;
                  margin-bottom:0;
                  letter-spacing: 0.7px;
                  &:nth-of-type(2) {
                    margin-top: 17px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  店舗紹介
  -------------------------------------*/
  #PageShop.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            #ContSubBox01 {
              h4 {
                font-size: 36px;
                font-weight: 700;
                text-align: center;
                letter-spacing: 3px;
                font-family: 'Lato', sans-serif;
                span {
                  display: inline-block;
                }
                span.disp_pc {
                  margin-left: 6px;
                  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  &:nth-of-type(3) {
                    margin-left: 5px;
                  }
                }
              }
              p {
                font-size: 18px;
                color: #888888;
                font-weight: 700;
                text-align: center;
                letter-spacing: 3.5px;
              }
              .bx-wrapper {  //画像スライドの部分
                position: relative;
                margin-top: 49px;
                .bx-viewport {
                  width: 800px !important;
                  height: 530px !important;
                  margin: 0 auto;
                  ul {
                    li {
                      img {
                        width: 800px;
                        height: auto;
                      }
                    }
                  }
                }
                .bx-controls {
                  .bx-controls-direction {
                    a {
                      background-color: #fff;
                      opacity: .95;
                      width: 60px;
                      height: 60px;
                      font-size: 0;
                      text-decoration: none;
                      text-indent:-9999px;
                      &:hover {
                        opacity: .7;
                      }
                    }
                    a.bx-prev {
                      position: absolute;
                      top: 235px;
                      left: 100px;
                      width: 60px;
                      height: 60px;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 19px;
                        height: 30px;
                        background: url(../img/arrow11.png) no-repeat;
                        background-position: left top;
                        background-size: 10px auto;
                        position: absolute;
                        top: 22px;
                        left: 25px;
                      }
                    }
                    a.bx-next {
                      position: absolute;
                      top: 235px;
                      right: 100px;
                      width: 60px;
                      height: 60px;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 19px;
                        height: 30px;
                        background: url(../img/arrow02.png) no-repeat;
                        background-position: left top;
                        background-size: 10px auto;
                        position: absolute;
                        top: 22px;
                        left: 25px;
                      }
                    }
                  }
                }
              }
              .custom-thumb {
                width: 800px;
                margin-top: 15px;
                font-size: 0;
                letter-spacing: normal;
                margin: 15px auto 0;
                a {
                  &:hover {
                    opacity: .7;
                  }
                  &.active{
                    position:relative;
                    &:after{
                      content:"";
                      display: block;
                      width:100%;
                      height:3px;
                      background-color:#111;
                      position:absolute;
                      bottom:0;
                      left:0;
                    }
                    //border-bottom: 3px solid #111111;
                    img{
                      opacity:0.3;
                    }
                  }
                  img {
                    width: 100px;
                    height: auto;
                  }
                }
              }
              dl {
                font-size: 16px;
                text-align: center;
                margin-top: 60px;
                dd {
                  line-height: 16px;
                  margin-bottom: 16px;
                }
              }
            }
          }
          #ContBox02 {
            margin-bottom: 153px;
            margin-top:70px;
            .mapArea {
              #Gmap01{
                width: 100%;
                height: 300px;
              }
              ul.txtLink {
                text-align: right;
                margin-top:12px;
                li.gmap {
                  height: 14px;
                  a {
                    font-size: 14px;
                    color: #888888;
                    line-height: 14px;
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 11px;
                      height: 11px;
                      background: url(../img/mark_google.png) no-repeat;
                      background-position: center center;
                      background-size: 11px auto;
                      margin-left: 6px;
                      // vertical-align: top;
                    }
                  }
                }
              }
            }
            >div {
              &:nth-of-type(2) {
                margin-top: 55px;
                &:after {
                  content: ".";
                  display: block;
                  height: 0;
                  font-size:0;
                  clear: both;
                  visibility:hidden;
                }
                figure {
                  float: left;
                  width: 500px;
                  img {
                    position: relative;
                    left: 139px;
                    width: 300px;
                  }
                }
                >div {
                  float: left;
                  width: 500px;
                  padding-left: 60px;
                  padding-right: 100px;
                  border-left: 1px solid #dcdcdc;
                  p{
                    margin-top: 25px;
                  }
                  dl {
                    font-size: 16px;
                    line-height: 16px;
                    margin-top: 41px;
                    dt {
                      margin-bottom: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  form 共通
  -------------------------------------*/
  #PageInquiry.pageIndex,
  #PageMail_kaitori.pageIndex,
  #PageCustomer_info.pageIndex,
  #PageInquiry.pageConfirm,
  #PageMail_kaitori.pageConfirm,
  #PageCustomer_info.pageConfirm {
    #Main{
      #ContBox01{
        >p{
          text-align:center;
          font-size:16px;
          line-height:32px;
          margin-bottom:5px;
          span{
            font-size:14px;
            padding-left:1.5em;
            text-indent:-1.5em;
            color:#888;
            display:block;
          }
        }
        .link{
          text-align:center;
          a{
            color:#888;
            text-decoration:underline;
            &:hover{
              text-decoration:none;
            }
          }
        }
        .contactBlock{
          border-top:1px solid #dcdcdc;
          border-bottom:1px solid #dcdcdc;
          display:table;
          width:100%;
          margin-top:54px;
          table-layout: fixed;
          padding:20px 0;
          div,ul{
            display:table-cell;
            vertical-align:middle;
          }
          div{
            width:350px;
            padding-left:30px;
          }
          dl{
            display: table;
            text-align:center;
            dt{
              font-size:14px;
              font-weight:700;
            }
            dd{
              color:#888;
              font-size:12px;
              line-height:1;
              letter-spacing: 2.5px;
              margin:10px 0 0;
              &:first-of-type{
                margin-top:5px;
                font-family: 'Lato', sans-serif;
                font-weight: 400;
                font-size:20px;
                color:#222;
                letter-spacing:4px;
                a{
                  color:#222;
                }
              }
            }
          }
          ul{
            font-size:0;
            letter-spacing:normal;
            li{
              display:inline-block;
              vertical-align:middle;
              width:300px;
              height:50px;
              line-height:46px;
              letter-spacing: 1px;
              &+li{
                margin-left:50px;
              }
            }
          }
        }
      }
      .formBlock{
        width:800px;
        margin:0 auto;
        margin-top:94px;
      }
      form{
        dl{
          width:100%;
          display: table;
          table-layout: fixed;
          &+dl{
            margin-top:60px;
          }
          dt,dd{
            display:table-cell;
            vertical-align:top;
            font-size:16px;
            color:#222;
          }
          dt{
            width:220px;
            font-weight: 700;
            line-height:20px;
            padding-top:10px;
            span{
              display:block;
              font-size:14px;
              color:#888;
              font-weight:500;
              margin-top:3px;
            }
          }
          dd{
            &:first-child{
              width:50px;
              padding-top:10px;
              span{
                display:block;
                width:40px;
                height:20px;
                line-height:20px;
                font-size: 12px;
                font-weight: 700;
                color:#fff;
                text-align: center;
                background:#b9b9b9;
                &.required{
                  background:#e75f4d;
                }
              }
            }
            &:last-child{
              width:530px;
              font-size:16px;
              input,textarea{
                width:100%;
                height:40px;
                padding-left:14px;
                background-color:#eeeeee;
                border-radius: 0 !important;
                &:focus{
                  background-color:#fff;
                  border:1px solid #222;
                }
                &.middle01{
                  width:330px;
                }
                &.middle02{
                  width:430px;
                }
                &.short01{
                  width:230px;
                }
                &.short02{
                  width:180px;
                }
                &[type="file"]{
                  display:none;
                }
              }
              textarea{
                height:200px;
                padding-top:14px;
                line-height:32px;
                resize: vertical;
              }
              .label_div{
                //font-size:0;
                //letter-spacing:normal;
                display:table;
                width:100%;
                position:relative;
                table-layout: fixed;
                &+.label_div{
                  margin-top:10px;
                }
                .mail_label{
                  width:117px;
                  height:30px;
                  border:1px solid #222;
                  font-size:14px;
                  font-weight:700;
                  line-height:30px;
                  text-align:center;
                  //background: url(../img/mark_file.png) no-repeat scroll left center;
                  //background-size: 117px auto;
                  //display:inline-block;
                  vertical-align:middle;
                  display:table-cell;
                  cursor:pointer;
                  &:hover{
                    opacity:0.7;
                  }
                }
                .statusBox{
                  vertical-align:middle;
                  width:335px;
                  padding:0 13px;
                  overflow:hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-wrap: break-word;
                  font-size:16px;
                  display:table-cell;
                  &#text10_status{
                    width:413px;
                  }
                }
                .reset_button{
                  //display:inline-block;
                  vertical-align:middle;
                  width:74px;
                  height:26px;
                  font-size:12px;
                  font-weight:700;
                  border-radius:20px!important;
                  background-image:url(../img/mark_clear.png);
                  background-repeat:no-repeat;
                  background-position:center left 14px;
                  background-size:10px auto;
                  padding-left:20px;
                  //display:table-cell;
                  position:absolute;
                  top:0;
                  right:0;
                  &:hover{
                    opacity:0.7;
                  }
                }
                &+span.note{
                  margin-top:5px;
                }
              }
              .radio{
                width: 20px;
                height: 20px;
                margin-top:20px;
                margin-right: 10px;
                &#uniform-text10_1{
                  margin-top:8px;
                }
                span{
                  width: 20px;
                  height: 20px;
                  background: transparent url(../img/btn_radio01_pc.png) no-repeat scroll left center;
                  background-size:20px 20px;
                  &.checked{
                    background: transparent url(../img/btn_radio02_pc.png) no-repeat scroll left center;
                    background-size:20px 20px;
                  }
                  input{
                    width:20px;
                    height:20px;
                    vertical-align:top;
                  }
                }
              }
              label{
                display:inline-block;
                vertical-align:bottom;
                line-height:20px;
              }
              span.note{
                color:#e75f4d;
                font-size:14px;
                display:block;
                line-height:24px;
              }
            }
          }
        }
        ul.contact-form1{
          position: relative;
          width:100%;
          margin:55px 0 175px;
          li{
            &.reset{
              text-align:center;
              margin-top:28px;
              input[type="button"]{
                color: #888;
                font-size: 14px;
                text-decoration: underline;
                &:hover{
                  text-decoration: none;
                }
              }
            }
            #image-btn{
              display: block;
              width: 440px;
              height: 70px;
              text-align:center;
              margin: 0px auto;
              background-color: #222;
              background-image:url(../img/arrow08.png);
              background-repeat:no-repeat;
              background-position:center right 22px;
              background-size:18px auto;
              opacity: 1;
              color: #fff;
              font-size: 16px;
              font-weight:700;
              text-decoration: none;
              line-height: 70px;
              border-radius: 0 !important;
              &:hover{
                opacity:0.7;
              }
            }
          }
        }
      }
    }
  }

  /*
  確認画面 共通
  -------------------------------------*/
  #PageInquiry.pageConfirm,
  #PageMail_kaitori.pageConfirm,
  #PageCustomer_info.pageConfirm{
    #Main{
      form{
        dl{
          &+dl{
            margin-top:40px;
          }
          dd:first-of-type,dt{
            padding-top:5px;
          }
          dd:last-of-type{
            line-height:32px;
          }
        }
      }
    }
  }

  /*
  完了画面 共通
  -------------------------------------*/
  #PageInquiry.pageThanks,
  #PageMail_kaitori.pageThanks,
  #PageCustomer_info.pageThanks {
    #Main{
      #ContBox01{
        >p{
          font-size:16px;
          line-height:32px;
          text-align:center;
        }
        >dl{
          width:300px;
          margin:50px auto 60px;
          border-top:1px solid #dcdcdc;
          border-bottom:1px solid #dcdcdc;
          text-align:center;
          padding:20px 0 10px;
          dt{
            font-size:14px;
            font-weight:700;
          }
          dd{
            color:#888;
            font-size:12px;
            line-height:1;
            letter-spacing: 2.5px;
            margin:3px 0 12px;
            &:first-of-type{
              font-family: 'Lato', sans-serif;
              font-weight: 400;
              font-size:20px;
              color:#222;
              letter-spacing:4px;
              a{
                color:#222;
              }
            }
          }
        }
        .linkBtn{
          width:280px;
          height:50px;
          line-height:50px;
          margin:60px auto 0;
        }
        .linkTxt{
          font-size:14px;
          text-align:center;
          a{
            color:#888;
            text-decoration:underline;
            &:hover{
              text-decoration:none;
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageInquiry.pageIndex {
    #Main{
      form{
        dl{
          &:nth-of-type(n+7){
            margin-top:34px;
          }
        }
      }
    }
  }

  /*
  確認画面（お問い合わせ）
  -------------------------------------*/
  #PageInquiry.pageConfirm {
  }

  /*
  完了画面（お問い合わせ）
  -------------------------------------*/
  #PageInquiry.pageThanks {
  }

  /*
  メール査定フォーム
  -------------------------------------*/
  #PageMail_kaitori.pageIndex {
    sup{
      color:#888;
    }
    #Main{
      form{
        dl{
          &:first-of-type,&:nth-of-type(2),&:nth-of-type(3){
            dt{
              line-height:24px;
              padding-top:8px;
            }
            dd:last-of-type{
              padding-top: 3px;
            }
          }
        }
      }
    }
  }

  /*
  確認画面（メール査定フォーム）
  -------------------------------------*/
  #PageMail_kaitori.pageConfirm {
  }

  /*
  完了画面（メール査定フォーム）
  -------------------------------------*/
  #PageMail_kaitori.pageThanks {
  }

  /*
  LINE査定について,宅配買取について,出張買取について
  共通
  -------------------------------------*/
  #PageLine.pageIndex,
  #PageTakuhai_kaitori.pageIndex,
  #PageHome_kaitori.pageIndex{
    #Main{
      ol.flow{
        width:100%;
        display:table;
        list-style:none;
        li{
          display:table-cell;
          vertical-align:top;
          width:238px;
          padding-top:20px;
          padding-bottom:40px;
          padding-right:26px;
          border-right: 1px solid #dcdcdc;
          position:relative;
          &:after{
            content:"";
            width:11px;
            height:12px;
            display:block;
            background:url(../img/arrow09.png) no-repeat right top;
            background-size:11px auto;
            position:absolute;
            right:-8px;
            top:55px;
          }
          &:nth-child(2),&:nth-child(3){
            width:262px;
            padding-left: 26px;
          }
          &:last-child{
            padding-left:26px;
            padding-right:0;
            border:none;
            &:after{
              display:none;
            }
          }
          >dl{
            >dd{
              &:first-of-type{
                font-family: 'Lato', sans-serif;
                font-weight: 700;
                //padding-bottom:40px;
                padding-bottom:5px;
                text-align:center;
                font-size:14px;
                //background-image:url(../img/icon_01.png);
                background-size:26px auto;
                background-position:center 25px;
                background-repeat:no-repeat;
                span{
                  font-family: 'Oswald', sans-serif;
                  font-weight: 700;
                  display:block;
                  font-size:32px;
                  line-height:1;
                  padding-top:4px;
                }
              }
              &:nth-of-type(2){
                font-size:16px;
                line-height:28px;
              }
              figure{
                img{
                  width:100%;
                  height:auto;
                }
              }
            }
            >dt{
              font-size:16px;
              font-weight:700;
              display:table;
              border-bottom:3px solid #222;
              padding:0 3px 5px;
              margin:0 auto;
              margin-top:22px;
            }
          }
          &:nth-child(2){
            >dl{
              >dd{
                &:first-of-type{
                  //background-image:url(../img/icon_02.png);
                  background-size:33px auto;
                }
              }
            }
          }
          &:nth-child(3){
            >dl{
              >dd{
                &:first-of-type{
                  //background-image:url(../img/icon_03.png);
                  background-size:34px auto;
                }
              }
            }
          }
          &:nth-child(4){
            >dl{
              >dd{
                &:first-of-type{
                  //background-image:url(../img/icon_04.png);
                  background-size:34px auto;
                }
              }
            }
          }
        }
      }
      .grayBox{
        background-color:#eeeeee;
        width:100%;
        text-align:center;
        padding:0 15px 35px;
        dt{
          background-color:#222;
          font-size:16px;
          font-weight:700;
          color:#fff;
          height:40px;
          line-height:40px;
          padding:0 20px;
          display:inline-block;
          margin-bottom:25px;
        }
      }
    }
  }

  /*
  LINE査定について
  -------------------------------------*/
  #PageLine.pageIndex {
    #Container {
      #Main {
        .innerBasic {
          h3 {
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-family: 'Lato', sans-serif;
          }
          p {
            font-size: 18px;
            color: #888888;
            font-weight: 700;
            text-align: center;
            letter-spacing: 3px;
          }
          section.contSubBox.add_friend {
              width: 100%;
              height: 200px;
              background-color: #eeeeee;
              text-align: center;
              margin-top: 61px !important;
              margin-bottom: 119px;
              >dl {
                font-size: 16px;
                dt {
                  width: 148px;
                  height: 40px;
                  margin: 0 auto;
                  font-size: 16px;
                  line-height: 40px;
                  color: #ffffff;
                  letter-spacing: 1px;
                  // text-align: center;
                  background-color: #222222;
                }
                dd {
                  font-size: 14px;
                  margin-top: 27px;
                  letter-spacing: 0.7px;
                  &:nth-of-type(2) {
                    display: inline-block;
                    width: 70px;
                    height: 70px;
                    background-color: #ffffff;
                    position: relative;
                    margin-top: 11px;
                    img {
                      width: 58px;
                      height: 58px;
                      position: absolute;
                      top:0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      margin: auto;
                    }
                  }
                  &:nth-of-type(3) {
                    display: inline-block;
                    font-size: 18px;
                    position: relative;
                    top: -29px;
                    margin-left: 11px;
                    letter-spacing: 2px;
                    font-weight: 700;
                    font-family: 'Lato', sans-serif;
                    h4 {

                    }
                  }
                }
              }
          }
        }
        #ContBox01 {
          border-bottom: 1px solid #dcdcdc;
          margin-top: 105px;
          .innerBasic {
            .contSubBox {
              margin-top: 75px;
              font-size: 0;
              letter-spacing: normal;
              display: table;
              .desc_Line {
                width: 237px;
                height: 616px;
                display: table-cell;
                border-right: 1px solid #dcdcdc;
                position: relative;
                padding-left:0px;
                padding-right: 25px;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 11px;
                  height: 12px;
                  background: url(../img/arrow09.png) no-repeat;
                  // background-position: right center;
                  background-size: 11px auto;
                  position: absolute;
                  top: 31px;
                  right: -8px;
                }
                &:nth-of-type(2) {
                  width: 263px;
                  padding-left: 25px;
                  padding-right: 25px;
                  dl {
                    dt {
                      font-weight: 700;
                      font-family: 'Lato', sans-serif;
                    }
                    dd {
                      &:first-of-type {
                        text-align:center;
                        font-weight: 700;
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          right: 0;
                          bottom: -11px;
                          left: 0;
                          display: block;
                          margin: auto;
                          width: 76px;
                          height: 3px;
                          background: #222;
                        }
                      }
                    }
                  }
                  figure {
                    img {
                      position: relative;
                      top: 51px;
                      left: 25px;
                    }
                  }
                }
                &:nth-of-type(3) {
                  width: 263px;
                  padding-left: 24px;
                  padding-right: 24px;
                  dl {
                    dt {
                      font-family: 'Lato', sans-serif;
                    }
                    dd {
                      &:first-of-type {
                        text-align:center;
                        font-weight: 700;
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          right: 0;
                          bottom: -11px;
                          left: 0;
                          display: block;
                          margin: auto;
                          width: 103px;
                          height: 3px;
                          background: #222;
                        }
                      }
                      &:nth-of-type(2) {
                        span {
                          font-weight: 700;
                        }
                      }
                    }
                  }
                  figure {
                    img {
                      top: 22px;
                      left: 25px;
                    }
                  }
                }
                &:last-of-type {
                  border-right: none;
                  padding-left: 25px;
                  padding-right: 0px;
                  &:after {
                    background:none;
                  }
                  dl {
                    dt {
                      font-family: 'Lato', sans-serif;
                    }
                    dd {
                      &:first-of-type {
                        text-align:center;
                        font-weight: 700;
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          right: 0;
                          bottom: -11px;
                          left: 0;
                          display: block;
                          margin: auto;
                          width: 110px;
                          height: 3px;
                          background: #222;
                        }
                      }
                    }
                  }
                  figure {
                    img {
                      top: 51px;
                      left: 25px;
                    }
                  }
                }
                >dl {
                  dt {
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 38px;
                    text-transform: uppercase;
                    text-align: center;
                    position: relative;
                    font-weight: 700;
                    font-family: 'Lato', sans-serif;
                    span {
                      display: block;
                      font-size: 32px;
                      font-weight: 700;
                      font-family: 'Oswald', sans-serif;
                      margin-top: 17px;
                    }
                  }
                  dd {
                    font-size: 16px;
                    line-height: 16px;
                    span {
                      // display: inline-block;
                    }
                    &:first-of-type {
                      text-align:center;
                      font-weight: 700;
                      position: relative;
                      letter-spacing: 1px;
                      &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: -11px;
                        left: 0;
                        display: block;
                        margin: auto;
                        width: 93px;
                        height: 3px;
                        background: #222;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 33px;
                      line-height: 29px;
                      letter-spacing: 1px;
                    }
                  }
                }
                figure {
                  img {
                    width: 169px;
                    position: relative;
                    top: 22px;
                    left: 24px;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          margin-top: 107px;
          border-bottom: 1px solid #dcdcdc;
          .innerBasic {

            h3  {
              font-family: 'Lato', sans-serif;
            }
            p {
            }
            .contSubBox {
              margin-top: 48px;
              margin-bottom: 122px;
              dl {
                font-size: 16px;
                text-align: center;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  right: 0;
                  bottom: -22px;
                  left: 0;
                  display: block;
                  margin: auto;
                  width: 59px;
                  height: 1px;
                  background: #dcdcdc;
                }
                dt {
                  line-height: 16px;
                  font-weight: 700;
                }
                dd {
                  line-height: 16px;
                }
                &:first-of-type {
                  dd {
                    margin-top: 16px;
                  }
                }
                &:nth-of-type(2) {
                  dt {
                    margin-top: 48px;
                  }
                }
                &:nth-of-type(3) {
                  &:after {
                    display:none;
                  }
                  dt {
                    margin-top: 48px;
                  }
                }
              }
            }
          }
        }
        #ContBox03 {
          margin-top: -5px;
          .innerBasic {
            margin-top: 110px;
            margin-bottom: 170px;
            h3 {
              font-family: 'Lato', sans-serif;
            }
            p {
              &:nth-of-type(2) {
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #222222;
                margin-top: 44px;
                letter-spacing: 1px;
              }
              span {
                display: block;
              }
            }
            .contSubBox02 {
              margin-top: 52px;
              font-size: 0;
              letter-spacing: normal;
              >dl {
                width: 326px;
                height: 811px;
                border-right: 1px solid #dcdcdc;
                display: inline-block;
                padding-left: 26px;
                padding-right: 52px;
                padding-top: 30px;
                dt {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  color: #222222;
                  text-align: center;
                  text-transform: uppercase;
                  font-family: 'Lato', sans-serif;
                  letter-spacing: 2px;
                  padding-left: 8px;
                  span {
                    display: block;
                    font-size: 32px;
                    font-weight: 700;
                    margin-top: 19px;
                    padding-left: 0;
                    font-family: 'Oswald', sans-serif;
                  }
                }
                dd {
                  font-size: 16px;
                  text-align: center;
                  &:first-of-type {
                    font-weight: 700;
                    font-size: 16px;
                    position: relative;
                    margin-top: 32px;
                    letter-spacing: 2px;
                    &:after {
                      content: "";
                      position: absolute;
                      right: 0;
                      bottom: -7px;
                      left: 0;
                      display: block;
                      margin: auto;
                      width: 161px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(2) {
                    margin-top: 68px;
                    position: relative;
                    img {
                      width: 240px;
                    }
                    &:after {
                      content: "";
                      position: absolute;
                      right: 0;
                      bottom: -32px;
                      left: 3px;
                      display: block;
                      margin: auto;
                      width: 60px;
                      height: 1px;
                      background: #dcdcdc;
                    }

                  }
                  &:nth-of-type(3) {
                    margin-top: 99px;
                    font-weight: 700;
                    color: #e75f4d;
                    position: relative;
                    font-family: 'Oswald', sans-serif;
                    &:before {
                      content: "";
                      display: inline-block;
                      width: 23px;
                      height: 23px;
                      background: url(../img/icon_11.png) no-repeat;
                      background-position: center top;
                      background-size: 23px auto;
                      position: absolute;
                      top: -29px;
                      left: 0;
                      right: 0;
                      margin: auto;
                    }
                  }
                  &:nth-of-type(4) {
                    margin-top: 8px;
                  }
                  &:nth-of-type(5) {
                    margin-top: 9px;
                    letter-spacing: 1px;
                  }
                }
                &:nth-of-type(2) {
                  width: 351px;
                  display: inline-block;
                  padding-left: 56px;
                  padding-right: 56px;
                  dt {

                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 220px;
                        left: -2px;
                      }
                    }

                  }
                }
                &:nth-of-type(3) {
                  width: 322px;
                  display: inline-block;
                  border: none;
                  padding-left: 52px;
                  padding-right: 26px;

                  dt {

                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 200px;
                        left: -2px;
                      }
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  宅配買取について
  -------------------------------------*/
  #PageTakuhai_kaitori.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          margin-bottom: 113px;
          .innerBasic {
            h3 {
              font-size: 40px;
              color: #000000;
              font-weight: 700;
              text-align: center;
            }
            #ContSubBox01 {
              font-size: 0;
              letter-spacing: normal;
              h4 {
                font-size: 36px;
                font-weight: 700;
                font-family: 'Lato', sans-serif;
                text-align: center;
                letter-spacing: 3px;
              }
              p {
                font-size: 18px;
                color: #888888;
                font-weight: 700;
                text-align: center;
                letter-spacing: 3px;
                &:nth-of-type(2) {
                  color: #222222;
                  font-size: 20px;
                  line-height: 38px;
                  letter-spacing: 1px;
                  margin-top: 39px;
                }
                span {
                  display: block;
                }
              }
              >dl {  //OKのdl
                width: 50%;
                height: 340px;
                display: inline-block;
                text-align: center;
                margin-top: 30px;
                border-right: 1px solid #dcdcdc;
                padding-right: 22px;
                dt {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  font-family: 'Lato', sans-serif;
                  letter-spacing: 1px;
                  margin-top: 28px;
                  span {
                    display: block;
                    font-size: 32px;
                    line-height: 32px;
                    margin-top: 10px;
                    font-weight: 700;
                    font-family: 'Oswald', sans-serif;
                    //oswald
                  }
                }
                dd {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: 700;
                  letter-spacing: 1px;
                  position: relative;
                  span {
                    display: block;
                  }
                  &:first-of-type {
                    margin-top: 28px;
                    &:after {
                      content: "";
                      position: absolute;
                      bottom: -12px;
                      left: 142px;
                      display: block;
                      width: 191px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(2) {
                    margin-top: 48px;
                    img {
                      width: 56px;
                    }
                  }
                  &:nth-of-type(3) {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 34px;
                    line-height: 28px;
                  }
                }
                &:nth-of-type(2) {  //NGのdl
                  display: inline-block;
                  width: 50%;
                  padding-left: 50px;
                  border: none;
                  vertical-align: top;
                  margin-top: 30px;
                  dt {
                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 161px;
                        left: 134px;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 51px;
                      margin-left: 17px;
                      img {
                        width: 71px;
                      }
                    }
                    &:nth-of-type(3) {
                      margin-top: 29px;
                    }
                  }
                }
              }
              .adress {
                margin-top: 64px;
                font-size: 16px;
                text-align: center;
                dl {
                  background-color: #eeeeee;
                  padding-bottom: 47px;
                  dt {
                    width: 80px;
                    height: 40px;
                    background-color: #222222;
                    margin: 0 auto;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 40px;
                  }
                  dd {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    &:first-of-type {
                      margin-top: 29px;
                      span {
                        margin-left: 7px;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 16px;
                    }
                    &:nth-of-type(3) {
                      margin-top: 16px;
                    }
                    &:nth-of-type(4) {
                      font-size: 14px;
                      line-height: 14px;
                      font-weight: 700;
                      color: #888888;
                      margin-top: 29px;
                      margin-left: 15px;
                      font-weight: 500;
                    }
                    &:nth-of-type(5) {
                      font-size: 14px;
                      line-height: 14px;
                      font-weight: 700;
                      color: #888888;
                      margin-top: 14px;
                      margin-left: 9px;
                      font-weight: 500;
                    }
                  }
                }
                p {
                  font-size: 16px;
                  color: #222222;
                  margin-top: 22px;
                  margin-bottom: 0;
                  letter-spacing: 1px;
                  font-weight: 500;
                  a {
                    color: #888888;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          padding-top: 35px;
          border-top: 1px solid #dcdcdc;
          #ContSubBox02 {
            .innerBasic {
              h3 {
                font-size: 36px;
                font-weight: 700;
                font-family: 'Lato', sans-serif;
                text-align: center;
                margin-top: 70px;
                letter-spacing: 3px;
              }
              p {
                font-size: 18px;
                color: #888888;
                font-weight: 700;
                text-align: center;
                &:first-of-type {
                  letter-spacing: 3px;
                }
                &:nth-of-type(2) {
                  margin-top: 49px;
                  font-size: 20px;
                  line-height: 20px;
                  color: #222222;
                  letter-spacing: 1px;
                  span {
                    display: block;
                    margin-top: 16px;
                  }
                }
              }
              dl {
                margin-top: 43px;
                display: inline-block;
                padding-top: 30px;
                padding-bottom: 25px;
                border-bottom: 1px solid #dcdcdc;
                font-size: 0;
                letter-spacing: normal;
                dt {
                  display: inline-block;
                  vertical-align: middle;
                  width: 293px;
                  font-size: 24px;
                  color: #222222;
                  font-weight:700;
                  letter-spacing: 2px;
                }
                dd {
                  width: 707px;
                  display: inline-block;
                  vertical-align: middle;
                  padding-top: 30px;
                  padding-bottom: 30px;
                  border-left: 1px solid #dcdcdc;
                  font-size: 16px;
                  padding-left: 28px;
                  margin-top: -3px;
                  letter-spacing: 0.8px;
                  span {
                    display: block;
                    margin-top: 7px;
                  }
                }
                &:first-of-type {
                  border-top: 1px solid #dcdcdc;
                }
                &:nth-of-type(2) {
                  margin-top: 0;
                }
              }
            }
          }
          #ContSubBox03 {
            margin-top: 60px;
            >dl {
              width: 1000px;
              margin: 60px auto 0 auto;
              >dt {
                font-size: 24px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                letter-spacing: 2.4px;
                margin-left: 5px;
                span {

                }
              }
              >dd {
                background-color: #eeeeee;
                padding: 10px 10px;
                font-size: 0;
                letter-spacing: normal;
                margin-top: 38px;
                height: 200px;
                >dl {
                  vertical-align: middle;
                  width: 300px;
                  height: 140px;
                  margin-top: 22px;
                  margin-left: 20px;
                  display: inline-block;
                  background-color: #ffffff;
                  >dd {
                    text-align: center;
                    background-color: #ffffff;
                    margin-top: 27px;
                    img {
                      width: 55px;
                    }
                  }
                  >dt {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    margin-top: 18px;
                    margin-left: 33px;
                    position: relative;
                    &:before {
                      content: "";
                      display: inline-block;
                      width: 21px;
                      height: 21px;
                      background: url(../img/mark_check.png) no-repeat;
                      background-position: left center;
                      background-size: 21px auto;
                      position: absolute;
                      top: 0;
                      left: 69px;
                    }
                  }
                  &:nth-of-type(2) {
                    >dd {
                      margin-top: 30px;
                      img {
                      width: 47px;
                      }
                    }
                    >dt {
                      margin-left: 35px;
                      margin-top: 23px;
                      span {
                        font-size: 14px;
                        color: #888888;
                        margin-left: 15px;
                        letter-spacing: 1px;
                      }
                      &:before {
                        top: 0;
                        left: 0;
                      }
                    }
                  }
                  &:nth-of-type(3) {
                    >dd {
                      img {
                      width: 44px;
                      }
                    }
                    >dt {
                      &:before {
                        top: 0;
                        left: 70px;
                      }
                    }
                  }
                }
              }
            }
          }
          #ContSubBox04 {
            margin-top: 30px;
            margin-bottom: 198px;
            .innerBasic {
              font-size: 0;
              letter-spacing: normal;
              h4 {
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                position: relative;
                margin-top: 132px;
                letter-spacing: 2px;
                &:before {
                  content: "";
                  display: inline-block;
                  width: 55px;
                  height: 54px;
                  background: url(../img/mark_box02.png) no-repeat;
                  background-position: center top;
                  background-size: 55px auto;
                  position: absolute;
                  top: -70px;
                  left: 473px;
                }
              }
              p {
                text-align: center;
                margin-top: 26px;
                span {
                  display: block;
                }
              }
              >dl {
                margin-top: 39px;
                width: 500px;
                height: 485px;
                display: inline-block;
                text-align: center;
                border-right: 1px solid #dcdcdc;
                padding-right: 24px;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -13px;
                  left: 0px;
                  display: block;
                  width: 485px;
                  height: 1px;
                  background: #dcdcdc;
                }
                >dt {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  font-family: 'Lato', sans-serif;
                  margin-top: 25px;
                  letter-spacing: 1px;
                }
                >dd {
                  &:first-of-type {
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 700;
                    margin-top: 7px;
                    font-weight: 700;
                    font-family: 'Oswald', sans-serif;
                    //oswald
                  }
                  &:nth-of-type(2) {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-top: 31px;
                    letter-spacing: 1px;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      bottom: -11px;
                      left: 147px;
                      display: block;
                      width: 181px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(3) {
                    width: auto;
                    height: 250px;
                    position: relative;
                    margin-top: 11px;
                    img {
                      width: 380px;
                      height: auto;
                      position: absolute;
                      margin: auto;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                    }
                  }
                  &:nth-of-type(4) {
                    font-size: 14px;
                    line-height: 30px;
                    color: #ffffff;
                    font-weight: 700;
                    background-color: #b9b9b9;
                    width: 125px;
                    height: 30px;
                    margin: 0 auto;
                  }
                  &:nth-of-type(5) {
                    font-size: 16px;
                    line-height: 16px;
                    margin-top: 15px;
                    letter-spacing: 1px;
                  }
                }
                &:nth-of-type(2) {
                  border-right: none;
                  padding-right: 0;
                  padding-left: 28px;
                  &:after {
                    left: 14px;
                  }
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {

                    }
                    &:nth-of-type(3) {
                      img {
                        width: 358px;
                      }
                    }
                    &:nth-of-type(4) {

                    }
                    &:nth-of-type(5) {

                    }
                  }
                }
                &:nth-of-type(3) {
                  &:after {
                    display: none;
                  }
                  >dt {
                    margin-top: 19px;
                  }
                  >dd {
                    &:nth-of-type(2) {

                    }
                    &:nth-of-type(3) {
                      img {
                        width: 352px;
                      }
                    }
                    &:nth-of-type(4) {

                    }
                    &:nth-of-type(5) {
                    }
                  }
                }
                &:nth-of-type(4) {
                  border-right: none;
                  border-bottom: none;
                  padding-right: 0;
                  padding-left: 28px;
                  &:after {
                    display: none;
                  }
                  >dt {
                    margin-top: 19px;
                  }
                  >dd {
                    &:nth-of-type(2) {

                    }
                    &:nth-of-type(3) {
                      img {
                        width: 286px;
                      }
                    }
                    &:nth-of-type(4) {

                    }
                    &:nth-of-type(5) {
                    }
                  }
                }
              }
              ul {
                li {
                  width: 440px;
                  height: 70px;
                  border: 3px solid #222;
                  margin: 39px auto 0 auto;
                  a {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    line-height: 67px;
                    font-weight: 700;
                    display: block;
                    text-decoration: none;
                    position: relative;
                    letter-spacing: 1.5px;
                    span {
                    }
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 9px;
                      height: 15px;
                      background: url(../img/arrow02.png) no-repeat;
                      background-position: right;
                      background-size: 9px auto;
                      position: absolute;
                      top: 25px;
                      right: 27px;
                    }
                    &:hover {
                      background-color: #222222;
                      color: #ffffff;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 9px;
                        height: 15px;
                        background: url(../img/arrow01.png) no-repeat;
                        background-position: right;
                        background-size: 9px auto;
                        position: absolute;
                        top: 25px;
                        right: 27px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  出張買取について
  -------------------------------------*/
  #PageHome_kaitori.pageIndex {
    #Main{
      margin-bottom:170px;
      #ContBox01{
        >p{
          font-size:20px;
          font-weight:700;
          line-height:36px;
          color:#222;
          text-align:center;
          margin-top:45px;
        }
      }
      ol.flow{
        margin-top:50px;
        li{
          >dl{
            >dt{
              margin-bottom:35px;
            }
            >dd{
              &:nth-of-type(2){
                padding-top:90px;
                background-image:url(../img/mark_mail02.png);
                background-size:51px auto;
                background-position:center 10px;
                background-repeat:no-repeat;
                span{
                  display:block;
                  font-size:14px;
                  line-height:24px;
                  color:#888;
                  padding-left:1.4em;
                  text-indent:-1.4em;
                  margin-top:17px;
                }
              }
              figure{
                margin-top:20px;
              }
              dl{
                border-top:1px solid #dcdcdc;
                margin-top:20px;
                padding-top:26px;
                text-align:center;
                dt{
                  font-size:18px;
                  font-family: 'Lato', sans-serif;
                  letter-spacing: 3.5px;
                  a{
                    color:#222;
                  }
                }
                dd{
                  font-size:12px;
                  color:#888;
                  letter-spacing: 2.5px;
                }
              }
              .linkBtn{
                margin-top:20px;
                width:100%;
                height:50px;
                line-height:50px;
              }
            }
          }
          &:nth-child(2){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_calendar.png);
                  background-size:49px auto;
                  background-position:center 6px;
                }
              }
            }
          }
          &:nth-child(3){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_car.png);
                  background-size:63px auto;
                  background-position:center 10px;
                }
              }
            }
          }
          &:nth-child(4){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_money.png);
                  background-size:56px auto;
                  background-position:center top;
                }
              }
            }
          }
        }
      }
      .grayBox{
        margin-top:60px;
        dd{
          .fontB{
            font-size:24px;
            font-weight:700;
          }
          .note{
            font-size:14px;
            color:#888;
            display:block;
            margin-top:20px;
          }
        }
      }
    }
  }

  /*
  運営会社
  -------------------------------------*/
  #PageCompany.pageIndex {
    #Main{
      padding-top: 15px;
      margin-bottom: 207px;
      dl{
        font-size:16px;
        line-height: 32px;
        color:#222;
        border-top:1px solid #dcdcdc;
        width:100%;
        display: table;
        padding:23px 0 24px;
        &:last-of-type{
          border-bottom:1px solid #dcdcdc;
        }
        dt,dd{
          display:table-cell;
          vertical-align: top;
        }
        dt{
          width:204px;
        }
        a{
          color:#888;
          text-decoration:underline;
          &:hover{
            text-decoration:none;
          }
          &.tel{
            color:#222;
            text-decoration:none;
          }
        }
      }
    }
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePrivacy.pageIndex {
    #Main{
      p,li,dt,dd{
        font-size:16px;
        line-height:32px;
        text-align: justify;
        text-justify: inter-ideograph;
      }
      h3{
        font-size:40px;
        font-weight:700;
        color:#000;
        text-align:center;
        margin-bottom:100px;
        letter-spacing:7px;
      }
      .contBox{
        >p{
          text-align:center;
        }
        .contSubBox{
          margin-top:80px;
          border-top:1px solid #dcdcdc;
          padding:0 100px;
          padding-bottom:140px;
          h4{
            font-size:24px;
            font-weight:700;
            margin-top:77px;
            margin-bottom:30px;
            letter-spacing: 2px;
          }
          p{
            margin-bottom:0;
            &+p{
              margin-top:32px;
            }
          }
        }
      }
      #ContBox01{
        h3{
          display:none;
        }
        .contSubBox{
          >dl{
            margin-top:34px;
            dd{
              dl{
                display:table;
                width:100%;
                dt,dd{
                  display:table-cell;
                  vertical-align:middle;
                }
                dt{
                  width:80px;
                  position:relative;
                  &:after{
                    content:"：";
                    position:absolute;
                    top:0;
                    right:0;
                  }
                }
                dd{
                  padding-left:0.5em;
                }
              }
            }
          }
        }
      }
      #ContBox02{
        padding-top:140px;
        border-top:1px solid #dcdcdc;
        .contSubBox{
          >ol{
            counter-reset: item;
            list-style-type: none;
            padding-left: 0;
            >li{
              position:relative;
              &:before{
                counter-increment: item;
                content: counter(item)'.';
                position:absolute;
                top:0;
                left:0;
              }
              padding-left:1.2em;
              &+li{
                margin-top:5px;
              }
              dl{
                margin-top: 5px;
                dt{
                  font-weight:700;
                }
              }
              ol{
                list-style: none;
                &.note{
                  margin-top: 10px;
                  li{
                    font-size:14px;
                    line-height:24px;
                    color:#888;
                    ul{
                      padding-left: 2.2em;
                    }
                  }
                  >li{
                    position:relative;
                    padding-left:2.2em;
                    text-indent:-2.2em;
                    span{
                      padding-right: 0.5em;
                    }
                    &+li{
                      margin-top:15px;
                    }
                  }
                }
              }
            }
          }
          .link{
            margin-top:8px;
            &:before{
              content:"＞";
              color:#888;
              margin-right:6px;
            }
            a{
              color:#888;
              text-decoration:underline;
              &:hover{
                text-decoration:none;
              }
            }
          }
        }
      }
    }
  }

  /*
  お客様情報送信フォーム
  -------------------------------------*/
  #PageCustomer_info {
    #Header{
      #ContBoxHeader{
        dl,#HeaderMenu{
          display:none;
        }
      }
    }
    #GlobalNav,#TopicPath{
      display:none;
    }
    #Footer .footerMain dl,#PageTop{
      display:none;
    }
    #Main{
      #ContBox01{
        .link{
          display:table;
          margin:0 auto;
          padding-right:25px;
          background:url(../img/mark_google.png) no-repeat top 3px right;
          background-size:11px auto;
        }
      }
    }
  }

  /*
  確認画面（お客様情報送信フォーム）
  -------------------------------------*/
  #PageCustomer_info.pageConfirm {
  }

  /*
  完了画面（お客様情報送信フォーム）
  -------------------------------------*/
  #PageCustomer_info.pageThanks {
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  共通
  -------------------------------------*/
  #Main{
    h3.enTitle{
      margin:65px 0 20px;
      text-align:center;
      font-size:18px;
      color:#888;
      font-weight:700;
      letter-spacing: 2.8px;
      >span{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size:36px;
        color:#222;
        display:block;
        line-height:1;
        margin-bottom:7px;
        span{
          display:block;
          margin-bottom:4px;
        }
      }
    }
    .linkBtn{
      a{
        display:block;
        width:100%;
        height:100%;
        text-align:center;
        border:2px solid #222;
        font-size:14px;
        font-weight:700;
        color:#222;
        background-color:transparent;
        background-image:url(../img/arrow02.png);
        background-repeat:no-repeat;
        background-position:center right 18px;
        background-size:9px auto;
        text-decoration:none;
      }
      li{
        &.mail{
          a{
            border:2px solid #8e6420;
            background-image: url(../img/arrow06.png);
            color:#8e6420;
          }
        }
        &.line{
          a{
            border:2px solid #178d17;
            background-image: url(../img/arrow07.png);
            color:#178d17;
          }
        }
      }
    }
  }

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          #TopImg {
            background: url(../img/img03.jpg) no-repeat center center;
            background-size: 960px auto;
            height: 480px;
            padding-top: 70px;
            .innerBasic {
              padding: 0 15px;
              p {
                color: #fff;
                text-align: center;
                span {
                  display: block;
                }
                &:first-of-type {
                  font-size: 18px;
                  font-weight: 700;
                  letter-spacing: 1.5px;
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: -17px;
                    left: 0;
                    right:0;
                    margin: auto;
                    display: block;
                    width: 240px;
                    height: 3px;
                    background: #fff;
                  }
                }
                &:nth-of-type(2) {
                  font-size: 20px;
                  line-height: 30px;
                  letter-spacing: 6px;
                }
              }
              h2 {
                font-size: 30px;
                line-height: 37px;
                font-weight: 700;
                margin-top: 43px;
                margin-bottom: 3px;
                letter-spacing: 8.5px;
                text-align: center;
                background: linear-gradient(to right, #d9b883 0%,#f9efe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                span {
                  display: block;
                }
              }
              dl{
                margin:58px 0 0;
                border:2px solid #2a2928;
                height:50px;
                font-size:0;
                letter-spacing:normal;
                background:rgba(255,255,255,0.9);
                position:relative;
                dt{
                  width:100px;
                  text-align:center;
                  height:30px;
                  font-size:14px;
                  font-weight:700;
                  color:#fff;
                  background:#2f2e2d;
                  line-height:30px;
                  position:absolute;
                  top:-35px;
                  left:0;
                  right:0;
                  margin:auto;
                  &:after{
                    content:"";
                    position:absolute;
                    bottom:-10px;
                    right:0;
                    left:0;
                    margin:auto;
                    width:0;
                    height:0;
                    border-style: solid;
                    border-width: 10px 10px 0 10px;
                    border-color: #2f2e2d transparent transparent transparent;
                  }
                }
                dd.serch_dd{
                  display:table;
                  width:100%;
                  height:100%;
                  line-height:46px;
                  font-size:0;
                  letter-spacing:normal;
                  form{
                    height:100%;
                    display:table-cell;
                    vertical-align:middle;
                    position:relative;
                    &:after{
                      content:"";
                      position:absolute;
                      top:0;
                      bottom:0;
                      right:0;
                      margin:auto;
                      background-color:#2b2a2a;
                      width:1px;
                      height:26px;
                    }
                    label{
                      width:100%;
                      height:100%;
                      display:block;
                      padding-left:52px;
                      background-image:url(../img/mark_camera.png);
                      background-repeat:no-repeat;
                      background-size:27px auto;
                      background-position:center left 14px;
                      div{
                        font-size:16px;
                        font-weight:700;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        overflow:hidden;
                      }
                    }
                    input{
                      display:none;
                    }
                  }
                  .mail_send{
                    width:74px;
                    display:table-cell;
                    vertical-align:middle;
                    font-size:14px;
                    font-weight:700;
                    text-align:center;
                    cursor: pointer;
                    &:after{
                      content:"";
                      display:inline-block;
                      vertical-align:top;
                      width:10px;
                      height:46px;
                      background:url(../img/arrow02.png) no-repeat center right;
                      background-size:10px auto;
                      margin-left:8px;
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          .innerbasic {
            padding: 0 15px 105px 15px;
            h3 {
              font-size: 36px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              text-align: center;
              margin-top: 67px;
              margin-bottom: 10px;
              line-height: 40px;
              letter-spacing: 3px;
              span {
                display: block;
              }
            }
            p {
              text-align: center;
              font-size: 18px;
              font-weight: 700;
              color: #888888;
              letter-spacing: 3px;
            }
            >div {
              text-align: center;
              >dl {
                margin-top: 43px;
                >dd {

                  &:first-of-type {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    font-family: 'Lato', sans-serif;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      bottom: -7px;
                      left: 0;
                      right:0;
                      margin: auto;
                      display: block;
                      width: 64px;
                      height: 1px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(2) {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  font-family: 'Lato', sans-serif;
                  margin-top: 16px;
                  text-transform: uppercase;
                  letter-spacing: 1.5px;
                  }
                  &:nth-of-type(3) {
                    font-size: 16px;
                    line-height: 28px;
                    color: #888888;
                    margin-top: 3px;
                    span {

                    }
                  }
                }
                >dt {
                  font-size: 24px;
                  font-weight: 700;
                  margin-top: 6px;
                  letter-spacing: 2px;
                }
                &:nth-of-type(2) {
                  margin-top: 19px;
                }
                &:nth-of-type(3) {
                  margin-top:22px;
                }
              }
            }
            ul {
              text-align: center;
              position: relative;
              margin-top: 67px;
              li {
                height: 60px;
                width: 255px;
                position: absolute;
                margin: auto;
                top:0;
                bottom:0;
                left:0;
                right:0;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  border: 3px solid #222;
                  background: url(../img/arrow02.png) no-repeat center right 12px;
                  background-size: 10px auto;
                  color: #222;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 56px;
                  text-decoration: none;
                }
              }
            }
          }
          #FooterMail {
            display: none;
          }
        }
        #ContBox03 {
          background: url(../img/img17.jpg) no-repeat center top;
          background-size: auto 1212px;
          height: 1212px;
          padding-top: 76px;
          .innerBasic {
            padding: 0 15px;
            p {
              color: #fff;
              font-weight: 700;
              text-align: center;
              letter-spacing: 2px;
              &:first-of-type {
                font-size: 16px;
                line-height: 16px;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -11px;
                  left: 0;
                  right:0;
                  margin: auto;
                  display: block;
                  width: 210px;
                  height: 3px;
                  background: #fff;
                }
              }
              &:nth-of-type(2) {
                font-size: 18px;
                line-height: 18px;
                color: #cccccc;
                margin: 16px 0 0;
              }
              &:nth-of-type(3) {
                font-size: 20px;
                line-height: 33px;
                margin-top: 24px;
                letter-spacing: 2px;
                span {
                  display: block;
                }
              }
            }
            h3 {
              font-size: 36px;
              line-height: 36px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              color: #fff;
              text-align: center;
              margin-top: 36px;
              letter-spacing: 3px;
              span {
                margin-top: 3px;
                display: block;
              }
            }
            >dl {
              >dt {
                color: #fff;
                background-color: #222;
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                width: 240px;
                height: 30px;
                line-height: 30px;
                margin: 0 auto;
                position: relative;
                margin-top: 20px;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 22px;
                  height: 9px;
                  background: url(../img/icon_13.png) no-repeat;
                  background-position: center bottom;
                  background-size: 22px auto;
                  position: absolute;
                  bottom: -9px;
                  left: 107px;
                }
              }
              >dd {
                padding: 16px 0 9px 0;
                margin-top: 14px;
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
                >div {
                  text-align: center;
                  &:first-of-type {
                    font-size: 16px;
                    color:#fff;
                    margin-top: -4px;
                    position: relative;
                    letter-spacing: 2px;
                    &:after {
                      content: "↓";
                      position: absolute;
                      bottom: -21px;
                      left: 0;
                      right: 11px;
                      margin: auto;
                      display: block;
                      width: 5px;
                      height: 15px;
                    }
                  }
                  &:nth-of-type(2) {
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;
                    margin-top: 35px;
                    letter-spacing: 1.5px;
                    span {
                      display: block;
                      font-size: 24px;
                      margin-top: -3px;
                      >span {
                        font-size: 16px;
                        display: inline-block;
                        margin-left: 2px;
                      }
                    }
                  }
                }
              }
            }
            >ul {
              margin-top: 24px;

              >li {
                position: relative;
                background-color: rgba(255,255,255,0.95);;
                width: 100%;
                height: 135px;
                >dl {
                  position: absolute;
                  left:0;
                  height: 73px;
                  margin: auto 0;
                  top: 0;
                  bottom: 0;
                  z-index: 1;
                  >dt {
                    height: 36px;
                    line-height: 36px;
                    display: inline-block;
                    padding-left: 12px;
                    padding-right: 9px;
                    background-color: rgba(34, 34, 34, .9);
                    color: #fff;
                    margin-top: 4px;
                    letter-spacing: .3px;
                  }
                  >dd {
                    height: 38px;
                    line-height: 38px;
                    padding-left: 12px;
                    letter-spacing: .5px;
                    span {
                      font-weight:700;
                    }
                  }
                }
                figure {  //鞄画像全体設定
                  width: 100%;
                  height: 135px;
                  position: relative;
                  img {
                    max-height: 100%;
                    height: auto;
                    width: auto;
                    position: absolute;
                    right: 0;
                    margin-left: auto;
                  }
                }
                >a {
                  width: 100%;
                  height: 100%;
                  display:block;
                }
                &:first-of-type {   //シャネル　ミニマトラッセ
                  margin-bottom: 12px;

                }
                &:nth-of-type(2) {  //エルメス　ケリー
                  margin-bottom: 13px;
                }
                &:nth-of-type(3) {  //ルイヴィトン　ネヴァーフル
                }
              }
              &:nth-of-type(2) {  //買い取り実績はこちらボタンのUL
                margin-top: 27px;
                li {
                  width: 254px;
                  height: 60px;
                  margin: 0 auto;
                  background: none;
                  text-align: center;
                  a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 56px;
                    border: 2px solid #fff;
                    background: transparent url(../img/arrow01.png) no-repeat center right 14px;
                    background-size: 9px auto;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    text-decoration: none;
                    letter-spacing: 1.5px;
                    &:hover {

                    }
                    span {

                    }
                  }
                }

              }
            }
          }
        }
        #ContBox04 {

          .customerWrap {
            background-color: #eeeeee;
            padding-top: 75px;
            padding-bottom: 76px;
            .innerbasic {
              padding: 0 15px;
              >p {
                font-weight: 700;
                text-align: center;
                letter-spacing: 2.5px;
                &:first-of-type {
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: -14px;
                    left: 0;
                    right:0;
                    margin: auto;
                    display: block;
                    width: 220px;
                    height: 3px;
                    background: #222;
                  }
                }
                &:nth-of-type(2) {
                  font-size: 18px;
                  line-height: 18px;
                  color: #888888;
                  letter-spacing: 3px;
                  margin-top: 14px;
                  margin-bottom: 0;
                }
              }
              h3 {
                text-align: center;
                font-size: 36px;
                line-height: 36px;
                font-family: 'Lato', sans-serif;
                font-weight:700;
                letter-spacing: 3px;
                margin-top: 34px;
                span {
                  margin-top: 1px;
                  display: block;
                }
              }
              >div {
                background-color: #fff;
                margin-top: 14px;
                padding: 30px 30px 8px;
                figure {
                  width: 150px;
                  margin: 0 auto;
                  img {
                    width: 150px;
                  }
                }
                >dl {
                  margin-top: 15px;
                  &:after {
                    content: ".";
                    display: block;
                    height: 0;
                    font-size:0;
                    clear: both;
                    visibility:hidden;
                  }
                  dt {
                    width: 78px;
                    position: relative;
                    font-size: 14px;
                    margin-bottom: 3px;
                    &:first-of-type {
                      background-color: #b9b9b9;
                      float: none;
                      width: 64px;
                      height: 20px;
                      font-size: 12px;
                      line-height: 20px;
                      text-align: center;
                      color: #fff;
                      font-weight: 700;
                      margin-bottom: 9px;
                    }
                  }
                  dd {
                    width: 145px;
                    float: right;
                    position: absolute;
                    left: 127px;
                    margin-top: -25px;
                    font-size: 14px;
                    letter-spacing: .5px;
                  }
                }
                >p {
                  border-top: 1px solid #dcdcdc;
                  margin-top: 12px;
                  padding-top: 14px;
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: .7px;
                }
                &:first-of-type {
                  margin-top: 44px;
                }
                &:nth-of-type(3) {

                }
              }
              ul {
                margin-top: 45px;
                li {
                  width: 254px;
                  height: 60px;
                  margin: 0 auto;
                  background: none;
                  text-align: center;
                  a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 56px;
                    border: 3px solid #222;
                    background: transparent url(../img/arrow04.png) no-repeat center right 14px;
                    background-size: 9px auto;
                    color: #222;
                    font-size: 16px;
                    font-weight: 700;
                    text-decoration: none;
                    letter-spacing: 1.5px;
                    &:hover {

                    }
                  }
                }
              }
            }
          }
          .shopWrap {
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #d9b883;
            >div {
              img {
                width: 100%;
                height: auto;
              }
              .innerbasic {
                background: url(../img/img_shop.png) no-repeat right bottom;
                background-size: 245px;
                padding: 0 15px 65px;
                background-color: #fff;

                h4 {
                  text-align: center;
                  font-size: 16px;
                  color: #fff;
                  line-height: 40px;
                  height: 40px;
                  width: 170px;
                  font-weight: 700;
                  background-color: #222;
                  margin: 0 auto;
                }
                >div {
                  text-align: center;
                  >dl {
                    margin-top: 30px;
                    >dd {
                      &:first-of-type {
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 700;
                        letter-spacing: 1.5px;
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          bottom: -12px;
                          left: 0;
                          right:0;
                          margin: auto;
                          display: block;
                          width: 260px;
                          height: 3px;
                          background: #222;
                        }
                      }
                      &:nth-of-type(2) {
                        font-size: 30px;
                        font-weight: 700;
                        line-height: 30px;
                        font-family: 'Lato', sans-serif;
                        margin-top: 13px;
                        letter-spacing: 3px;
                        span {
                          font-weight: 400;
                          &:nth-of-type(2) {
                            margin-left: -3px;
                          }
                        }
                      }
                      &:nth-of-type(3) {
                        margin: 22px auto 0;
                        width: 160px;
                        height: 44px;
                        a {
                          margin: 0 auto;
                          width: 100%;
                          height: 100%;
                          background: none;
                          text-align: center;
                          display: block;
                          margin: 0 auto;
                          text-decoration: none;
                          span {
                            display: block;
                            border: 1px solid #222;
                            width: 100%;
                            height: 100%;
                            background: transparent url(../img/arrow04.png) no-repeat center right 16px;
                            background-size: 9px auto;
                            line-height: 44px;
                            letter-spacing: 1.5px;
                            font-size: 14px;
                            font-weight: 700;
                            color: #222;
                            padding-right: 25px;
                          }
                          &:hover {

                          }
                        }
                      }
                    }
                    dt {
                      font-size: 24px;
                      line-height: 24px;
                      font-weight: 700;
                      margin-top: 34px;
                      letter-spacing: 2px;
                    }
                  }
                  &:nth-of-type(2) {

                    >dl {
                      margin-top: 23px;
                      >dd {
                        font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 32px;
                        letter-spacing: 1px;
                        text-align: left;

                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .identification {
            .innerbasic {
              padding: 0 15px;
              margin-bottom: 79px;
              h4 {
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                margin-top: 75px;
                letter-spacing: 2px;
              }
              dl {
                background-color: #eeeeee;
                padding: 30px 25px 25px 60px;
                margin-top: 10px;
                dt {
                  position: relative;
                  letter-spacing: 2px;
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    background: url(../img/mark_check.png) no-repeat;
                    background-position: left center;
                    background-size: 21px auto;
                    position: absolute;
                    top: -3px;
                    left: -30px;
                  }
                }
                dd {
                  font-size: 16px;
                  line-height: 26px;
                  letter-spacing: 1px;
                }
              }
              p {
                font-size: 14px;
                line-height: 14px;
                color: #888888;
                margin-bottom: 0;
                padding-left: 1.4em;
                text-indent: -1.4em;
                &:first-of-type {
                  margin-top: 22px;
                }
                &:nth-of-type(2) {
                  margin-top: 10px;
                  line-height: 23px;
                }
                &:nth-of-type(3) {
                  margin-top: 5px;
                  line-height: 24px;
                }
                &:nth-of-type(4) {
                  margin-top: 4px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    #Page.pageIndex{
      #Container {
        #Main {
          #ContBox01 {
            #TopImg {
              .innerBasic {
                h2 {
                  color: #eaceab;
                  background:transparent;
                  span {
                    color: #eaceab;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  はじめての方へ
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          .innerBasic {
            p {
              font-size: 20px;
              line-height: 32px;
              letter-spacing: 2px;
              font-weight: 700;
              &.disp_sp {
                margin-top: 30px;
              }
            }
          }
          section.contSubBox {
            width: 100%;
            background-color: #d9b883;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 69px;
            margin-bottom: 70px;
            >div {
              height: 270px;
              background: url("../img/01_main.jpg") no-repeat center center;
              background-size: 960px auto;
              position: relative;
              .div_small {
                margin:0 auto;
                position: absolute;
                top: 27px;
                left: 0;
                right:0;
                //transform: translateY(-50%) translateX(-50%);
                p {
                  margin:0;
                  color: #ffffff;
                  letter-spacing:1.9px;
                }
                h4 {
                  margin-top: 31px;
                  text-align: center;
                  font-size: 30px;
                  font-weight: 700;
                  letter-spacing:4.5px;
                  color: #ffffff;
                  padding-left: 20px;
                  padding-right: 15px;
                  span {
                    display: block;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #f5e9d5;
                    letter-spacing:0.8px;
                    margin-top: 7px;
                    background: linear-gradient(to right, #d9b883 0%,#f9efe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          margin-bottom: 100px;
          .innerBasic {
            .contSubBox02 {
              border-bottom: 1px solid #dcdcdc;
              &:first-child {
                border-top: 1px solid #dcdcdc;
              }
              .left {
                margin-top: 42px;
                dl {
                  text-align: center;
                  font-weight: 700;
                  dt {
                    font-size: 24px;
                    margin-top: 4px;
                    letter-spacing: 2px;
                    span {
                      display: block;
                    }
                  }
                  dd {
                    &:first-of-type {
                      position: relative;
                      font-size: 16px;
                      font-family: 'Lato', sans-serif;
                      font-weight: 700;
                      &:after {
                        position: absolute;
                        bottom:0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        content: "";
                        width: 63px;
                        height: 1px;
                        background-color: #222;
                      }
                    }
                    &:nth-of-type(2) {
                      font-size: 14px;
                      margin-top: 5px;
                      text-transform: uppercase;
                      letter-spacing: 1px;
                      font-family: 'Lato', sans-serif;
                      font-weight: 700;
                    }
                  }
                }

              }
              .right {
                margin-top: 14px;
                margin-bottom: 36px;
                p {
                  font-size: 16px;
                  line-height: 32px;
                  span {
                    display: block;
                  }
                }

              }
            }
            #ContSubBox01 {

            }
            #ContSubBox02 {

            }
            #ContSubBox03 {

            }
            #ContSubBox04 {

            }
            #ContSubBox05 {

            }
            #ContSubBox06 {
              .right {
                p {
                  span {
                    color: #888888;
                    font-size: 14px;
                    line-height: 24px;
                    padding-left:1.5em;
                    text-indent:-1.5em;
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    #PageAbout.pageIndex {
      #Container {
        #Main {
          #ContBox01 {
            section.contSubBox {
              >div {
                .div_small {
                  h4 {
                    span {
                      background:transparent;
                      color: #eaceab;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  査定・買取の方法
  -------------------------------------*/
  #PageService.pageIndex {
    #Main{
      margin-bottom:80px;
      .contBox{
        >div{
          background-color:#eeeeee;
          padding:30px 0;
          text-align:center;
          .innerBasic{
            padding:0 15px;
          }
          dl{
            background-color:#fff;
            padding:53px 0 45px;
            font-size:16px;
            font-weight:700;
            &+dl{
              margin-top:15px;
            }
            dd{
              &:first-of-type{
                max-width:290px;
                font-size:16px;
                border-bottom:3px solid #222;
                line-height:30px;
                display:inline-block;
                padding:0 3px;
                letter-spacing: 1.4px;
              }
              &:last-of-type{
                &.linkBtn{
                  margin:20px auto 0;
                  width:255px;
                  height:50px;
                  line-height:50px;
                }
                ul{
                  margin-top:20px;
                  li{
                    margin:0 auto;
                    width:255px;
                    height:50px;
                    line-height:50px;
                    &+li{
                      margin-top:15px;
                    }
                  }
                }
              }
            }
            dt{
              margin-top:33px;
              font-size:24px;
              padding-top:65px;
              background-image:url(../img/mark_mail02.png);
              background-repeat:no-repeat;
              background-position:center top 7px;
              background-size:51px auto;
              letter-spacing: 2px;
            }
          }
        }
      }
      #ContBox01{
        >div{
          dl{
            &:nth-child(2){
              dt{
                background-image:url(../img/mark_box03.png);
                background-position:center top;
                background-size:52px auto;
              }
            }
          }
        }
      }
      #ContBox02{
        >div{
          dl{
            &:nth-child(1){
              dt{
                background-image:url(../img/mark_shop.png);
                background-position:center top;
                background-size:51px auto;
              }
            }
            &:nth-child(2){
              dt{
                background-image:url(../img/mark_car.png);
                background-position:center top 7px;
                background-size:63px auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  買取実績
  -------------------------------------*/
  /*共通部分*/
  #PagePurchase_record{
    #Container{
      .selector{
        margin: 0 15px;
        margin-bottom: 30px;
        border: 2px solid #000;
        background: url("../img/selector.png") center right 17px no-repeat;
        background-size: 27px 26px;
        #text1{
          width: 100%;
          padding: 10px 0;
          padding-left: 12px;
          font-weight: bold;
          text-transform:uppercase;
        }
      }
      .subCate{
        background-color: #eeeeee;
        margin-bottom: 60px;
        ul{
          padding: 7px 0;
          text-align: center;
          font-size: 0;
          li{
            display: inline-block;
            margin: 5px 10px;
            a{
              font-size: 12px;
              font-weight: bold;
              // font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
              text-decoration: none;
            }
          }
          li.active,li.current-cat{
            border-bottom: 2px solid #000;
          }
          .disp_pc{
            display: none;
          }
        }
      }
      #Main ul li figure img{
        max-width: 230px;
        max-height: 230px;
        height:auto;
      }
      #Main{
        #ContBox01{
          #ContSubBox01{
            ul{
              li{
                dl{
                  dt{
                    span{
                      &+span{
                        &:before{
                          content:"/ ";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*共通部分ここまで*/
  #PagePurchase_record.pageIndex {
    #Container{
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              ul{
                margin:0 -15px;
                li{
                  border-top: 1px solid #dcdcdc;
                  &:last-of-type{
                    border-bottom: 1px solid #dcdcdc;
                  }
                  &::after{
                    content: "";
                    display: block;
                    clear: both;
                  }
                    a{
                      position: relative;
                      display:block;
                      padding: 31px 15px;
                      text-decoration: none;
                    figure{
                      position: absolute;
                      top: 50%;
                      -webkit-transform: translateY(-50%);
                         -moz-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                           -o-transform: translateY(-50%);
                              transform: translateY(-50%);
                      float: left;
                      img{
                        position: relative;
                        right: 10px;
                        width: 115px;
                      }
                    }
                    dl{
                      padding-left: 120px;
                      dt{
                        font-size: 12px;
                        display: inline-block;
                        color: #fff;
                        padding: 5px 10px;
                        margin-bottom: 13px;
                        background-color: rgba(34,34,34,0.9);
                        text-transform:uppercase;
                      }
                      dd{
                        font-size: 14px;
                        color: #888888;

                      }
                    }
                    h4{
                        margin-top: 2px;
                        padding-left: 120px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height:28px;
                    }
                  }
                }
              }
            }
            #PageNation{
              margin-top: 40px;
              margin-bottom: 104px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin-bottom: 40px;
                width: 100%;
                li{
                  margin-right: 54px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    font-size: 16px;
                    text-decoration: underline;
                    color: #888888;
                  }
                  span{
                    text-decoration: none;
                    }
                      &.active span{
                          color: #000000;
                          text-decoration: none;
                      }
                  }
              }
              >span{
                a{
                  margin: 0 15px;
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  //width: 100%;
                  font-size: 14px;
                  text-decoration: none;
                }
              }
              .pc_display {
                display: none!important;
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PagePurchase_record.pageCategory {
    #Container{
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              h3{
                text-align: center;
                margin-top: 45px;
                margin-bottom: 45px;
                span{
                  font-size: 16px;
                  font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  font-weight: bold;
                  border-bottom: 3px solid #000;
                  display: inline-block;
                  padding:0 2px 5px;
                }
              }
              ul{
                margin:0 -15px;
                li{
                  border-top: 1px solid #dcdcdc;
                  &:last-of-type{
                    border-bottom: 1px solid #dcdcdc;
                  }
                  &::after{
                    content: "";
                    display: block;
                    clear: both;
                  }
                    a{
                      display:block;
                      padding: 31px 15px;
                      text-decoration: none;
                      position: relative;
                    figure{
                      position: absolute;
                      top: 50%;
                      -webkit-transform: translateY(-50%);
                         -moz-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                           -o-transform: translateY(-50%);
                              transform: translateY(-50%);
                      float: left;
                      img{
                        position: relative;
                        right: 10px;
                        width: 115px;
                      }
                    }
                    dl{
                      padding-left: 120px;
                      dt{
                        font-size: 12px;
                        display: inline-block;
                        color: #fff;
                        padding: 5px 10px;
                        margin-bottom: 13px;
                        background-color: rgba(34,34,34,0.9);
                        text-transform:uppercase;
                      }
                      dd{
                        font-size: 14px;
                        color: #888888;

                      }
                    }
                    h4{
                        margin-top: 2px;
                        padding-left: 120px;
                        font-size: 16px;
                        line-height:28px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            #PageNation{
              margin-top: 40px;
              margin-bottom: 80px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin-bottom: 40px;
                width: 100%;
                li{
                  margin-right: 54px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    font-size: 16px;
                    text-decoration: underline;
                    color: #888888;
                  }
                  span{
                    text-decoration:none;
                    }
                      &.active span{
                          color: #000000;
                          text-decoration: none;
                      }
                  }
              }
              >span{
                a{
                  margin: 0 15px;
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  //width: 100%;
                  font-size: 14px;
                  text-decoration: none;
                }
              }
              .pc_display {
                display: none!important;
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  サブカテゴリ
  -------------------------------------*/
  #PagePurchase_record.pageCategory2 {
    #Container{
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              h3{
                text-align: center;
                margin-top: 45px;
                margin-bottom: 45px;
                span{
                  font-size: 16px;
                  font-family: "Lato", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  font-weight: bold;
                  border-bottom: 3px solid #000;
                  display: inline-block;
                  padding:0 2px 5px;
                }
              }
              ul{
                margin:0 -15px;
                li{
                  border-top: 1px solid #dcdcdc;
                  &:last-of-type{
                    border-bottom: 1px solid #dcdcdc;
                  }
                  &::after{
                    content: "";
                    display: block;
                    clear: both;
                  }
                    a{
                      display:block;
                      padding: 31px 15px;
                      text-decoration: none;
                      position: relative;
                    figure{
                      position: absolute;
                      top: 50%;
                      -webkit-transform: translateY(-50%);
                         -moz-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                           -o-transform: translateY(-50%);
                              transform: translateY(-50%);
                      float: left;
                      img{
                        position: relative;
                        right: 10px;
                        width: 115px;
                      }
                    }
                    dl{
                      padding-left: 120px;
                      dt{
                        font-size: 12px;
                        display: inline-block;
                        color: #fff;
                        padding: 5px 10px;
                        margin-bottom: 13px;
                        background-color: rgba(34,34,34,0.9);
                        text-transform:uppercase;
                      }
                      dd{
                        font-size: 14px;
                        color: #888888;

                      }
                    }
                    h4{
                        margin-top: 2px;
                        padding-left: 120px;
                        font-size: 16px;
                        line-height:28px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            #PageNation{
              margin-top: 40px;
              margin-bottom: 80px;
              text-align: center;
              font-size: 0;
              span,ul,li{display:inline-block;}
              ul{
                margin-bottom: 40px;
                width: 100%;
                li{
                  margin-right: 54px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  a,span{
                    font-size: 16px;
                    text-decoration: underline;
                    color: #888888;
                  }
                  span{
                    text-decoration:none;
                    }
                      &.active span{
                          color: #000000;
                          text-decoration: none;
                      }
                  }
              }
              >span{
                a{
                  margin: 0 15px;
                  border: 2px solid #222222;
                  display: block;
                  padding: 9px 0;
                  //width: 100%;
                  font-size: 14px;
                  text-decoration: none;
                }
              }
              .pc_display {
                display: none!important;
              }
              .prev{
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                  &:hover{
                    background-image: url("../img/arrow12.png");
                  }
                }
              }
              .next{
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                  &:hover{
                    background-image: url("../img/arrow01.png");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  実績詳細
  -------------------------------------*/
  #PagePurchase_record.pageEntry {
    #Container{
      .innerBasic{
        #Main{
          #ContBox01{
            #ContSubBox01{
              margin-top: 60px;
              padding-bottom: 40px;
              border-bottom: 1px solid #dcdcdc;
              margin: 0 -15px;
              .innerbasic{
                padding:0 15px;
                dl{
                  text-align: center;
                  dt{
                    display: inline-block;
                    background-color: rgba(34,34,34,0.9);
                    text-transform:uppercase;
                    color: #fff;
                    font-size: 12px;
                    margin-bottom: 10px;
                    padding: 6px 12px;
                    span{
                      &+span{
                        &:before{
                          content:"/ ";
                        }
                      }
                    }
                  }
                  dd{
                    font-size: 14px;
                    color: #888888;
                  }
                }
                h4{
                  margin-top: 10px;
                  margin-bottom: 50px;
                  text-align: center;
                    font-size: 24px;
                    font-weight: bold;
                }
                div{
                  p{
                    font-size: 16px;
                    line-height: 32px;
                    margin-bottom: 30px;
                  }
                }
                figure{
                  text-align: center;
                  img{
                    max-width: 220px;
                  }
                }
              }
            }
          }
        }
      }
      #PageNation{
              margin-top: 35px;
              margin-bottom: 104px;
              text-align: center;
              font-size: 0;
              span{display:inline-block;}
              span{
                border: 2px solid #222222;
                a{
                  display: block;
                  padding: 9px 0;
                  width: 100%;
                  font-size: 14px;
                  text-decoration: none;
                }
              }
              .prev{
                margin: 0 15px;
                a{
                  background: url("../img/arrow11.png") center left 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 37px;
                  padding-right: 20px;
                }
              }
              .back{
                margin-bottom: 35px;
                border: none;
                display: block;
                a{
                  color: #888888;
                  text-decoration: underline;
                }
              }
              .next{
                margin: 0 15px;
                a{
                  background: url("../img/arrow02.png") center right 15px no-repeat;
                  background-size: 10px 16px;
                  padding-left: 20px;
                  padding-right: 37px;
                }
              }
              .prev.pc_display {
                display: none!important;
              }
            }
    }
  }

  /*
  お客様の声
  -------------------------------------*/
  #PageCustomer_review.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 70px;
            margin-bottom: 84px;
            #ContSubBox01 {
              .largBox {
                margin-top: 65px;
                &:first-of-type {
                  margin-top: 0;
                }
                figure {
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                >div {
                  border-bottom: 1px solid #dcdcdc;
                  padding-bottom: 17px;
                  h3 {
                    font-size: 14px;
                    line-height: 30px;
                    color: #ffffff;
                    font-weight: 700;
                    width: 80px;
                    height: 30px;
                    background-color: #b9b9b9;
                    text-align: center;
                    margin-top: 30px;
                  }
                  dl {
                    &:first-of-type {
                      margin-top: 14px;
                    }
                    &:last-of-type {
                      // margin-bottom: 30px;
                    }
                    dt {
                      display: inline-block;
                      color: #888888;
                      font-size: 16px;
                      line-height: 16px;
                      width: 96px;
                      margin-bottom: 11px;
                    }
                    dd {
                      display: inline-block;
                      font-size: 16px;
                      line-height: 16px;
                    }
                  }
                }
              }
              .smallBox {
                padding-top: 22px;
                p {
                  font-size: 16px;
                  line-height: 32px;
                  margin-bottom:0;
                  &:nth-of-type(2) {
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  /*
  店舗紹介
  -------------------------------------*/
  #PageShop.pageIndex {
    #Container {
      .innerBasic {
        padding:0;
        #Main {
          #ContBox01 {
            margin-top: 69px;
            #ContSubBox01 {
              h4 {
                font-size: 36px;
                line-height: 38px;
                font-weight: 700;
                text-align: center;
                position: relative;
                margin-bottom: 10px;
                font-family: 'Lato', sans-serif;
                &:before {
                  content:"「";
                  position:absolute;
                  top: -2px;
                  left: 47px;
                  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                }
                &:after {
                  content:"」";
                  position:absolute;
                  bottom: -4px;
                  right: 45px;
                  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                }
                span {
                  display: block;
                }
                .disp_pc {
                  display: none;
                }
              }
              p {
                font-size: 18px;
                color: #888888;
                text-align: center;
                font-weight: 700;
                letter-spacing: 3px;
              }
              .bx-wrapper {  //画像スライドの部分
                position: relative;
                margin-top: 49px;
                .bx-viewport {
                  width: 100%;
                  height: auto;
                  margin: 0 auto;
                  ul {
                    li {
                      img {
                        width: 100%;
                        height: auto;
                      }
                    }
                  }
                }
                .bx-controls {
                  .bx-controls-direction {
                    a {
                      background-color: #fff;
                      opacity: .95;
                      width: 60px;
                      height: 60px;
                      font-size: 0;
                      text-decoration: none;
                      text-indent:-9999px;
                    }
                    a.bx-prev {
                      position: absolute;
                      top: 0;
                      bottom:0;
                      margin:auto;
                      left: 0;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 19px;
                        height: 30px;
                        background: url(../img/arrow11.png) no-repeat;
                        background-position: left top;
                        background-size: 10px auto;
                        position: absolute;
                        top: 22px;
                        left: 25px;
                      }
                    }
                    a.bx-next {
                      position: absolute;
                      top: 0;
                      bottom:0;
                      margin:auto;
                      right: 0;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 19px;
                        height: 30px;
                        background: url(../img/arrow02.png) no-repeat;
                        background-position: left top;
                        background-size: 10px auto;
                        position: absolute;
                        top: 22px;
                        left: 25px;
                      }
                    }
                  }
                }
              }
              .custom-thumb {
                width: 100%;
                margin-top: 10px;
                font-size: 0;
                letter-spacing: normal;
                text-align:center;
                a {
                  display:inline-block;
                  width:8px;
                  height:8px;
                  background-color:#b9b9b9;
                  border-radius:50%;
                  margin: 5px 7px;
                  img {
                    display:none;
                  }
                  &.active{
                    background-color:#222222;
                  }
                }
              }
              dl {
                margin-top:40px;
                text-align: center;
                font-size: 16px;
                dd {
                  line-height: 16px;
                  margin-bottom: 17px;
                }
              }
            }
          }
          #ContBox02 {
            padding: 0 15px;
            margin-bottom: 91px;
            margin-top:40px;
            .mapArea {
              #Gmap01{
                width:100%;
                height:300px;
              }
              ul.txtLink {
                text-align: right;
                margin-top:10px;
                li.gmap {
                  height: 14px;
                  a {
                    font-size: 14px;
                    color: #888888;
                    line-height: 14px;
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 11px;
                      height: 11px;
                      background: url(../img/mark_google.png) no-repeat;
                      background-position: center center;
                      background-size: 11px auto;
                      margin-left: 6px;
                      // vertical-align: top;
                    }
                  }
                }
              }
            }
            >div {
              &:nth-of-type(2) {
                margin-top: 33px;

                figure {
                  width: 100%;
                  height: 260px;
                  position: relative;
                  img {
                    position: absolute;
                    top: 0;
                    left:0;
                    right: 0;
                    bottom:0;
                    margin:auto;
                    width: 225px;
                    height: auto;
                  }
                }
                >div {
                  margin-top: 20px;
                  p{
                    line-height: 32px;
                    letter-spacing: 1.6px;
                  }
                  dl {
                    margin-top: 39px;
                    font-size: 16px;
                    dt {
                      line-height: 16px;
                    }
                    dd {
                      margin-top: 17px;
                      line-height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  form 共通
  -------------------------------------*/
  #PageInquiry.pageIndex,
  #PageMail_kaitori.pageIndex,
  #PageCustomer_info.pageIndex,
  #PageInquiry.pageConfirm,
  #PageMail_kaitori.pageConfirm,
  #PageCustomer_info.pageConfirm {
    .innerBasic{
      padding:0;
    }
    #Main{
      #ContBox01{
        >p{
          font-size:16px;
          line-height:32px;
          margin-bottom:5px;
          padding:0 15px;
          span{
            font-size:14px;
            line-height:24px;
            padding-left:1.5em;
            text-indent:-1.5em;
            color:#888;
            display:block;
          }
        }
        .link{
          margin:0 15px;
          a{
            color:#888;
            text-decoration:underline;
          }
        }
        .contactBlock{
          border-top:1px solid #dcdcdc;
          border-bottom:1px solid #dcdcdc;
          width:100%;
          margin-top:54px;
          padding:26px 15px;
          div,ul{
          }
          dl{
            display: table;
            text-align:center;
            margin:0 auto 18px;
            dt{
              font-size:14px;
              font-weight:700;
            }
            dd{
              color:#888;
              font-size:12px;
              line-height:1;
              letter-spacing: 2.5px;
              margin:3px 0 12px;
              &:first-of-type{
                font-family: 'Lato', sans-serif;
                font-weight: 400;
                font-size:20px;
                color:#222;
                letter-spacing:4px;
                a{
                  color:#222;
                  text-decoration:underline;
                }
              }
            }
          }
          ul{
            li{
              width:100%;
              height:50px;
              line-height:46px;
              letter-spacing: 1px;
              &+li{
                margin-top:15px;
              }
            }
          }
        }
      }
      .contSubBox{
        padding:0 15px;
      }
      .formBlock{
        margin-top:60px;
      }
      form{
        dl{
          position:relative;
          &+dl{
            margin-top:45px;
          }
          dt,dd{
            font-size:16px;
            color:#222;
          }
          dt{
            font-weight: 700;
            line-height:20px;
            padding-left:53px;
            margin-bottom:15px;
            span{
              font-size:14px;
              color:#888;
              display:block;
              font-weight: 500;
              margin-top:3px;
            }
          }
          dd{
            &:first-child{
              position:absolute;
              top:0;
              left:0;
              span{
                display:block;
                width:40px;
                height:20px;
                line-height:20px;
                font-size: 12px;
                font-weight: 700;
                color:#fff;
                text-align: center;
                background:#b9b9b9;
                &.required{
                  background:#e75f4d;
                }
              }
            }
            &:last-child{
              font-size:16px;
              input,textarea{
                width:100%;
                height:40px;
                padding-left:12px;
                background-color:#eeeeee;
                border-radius:0!important;
                &:focus{
                  background-color:#fff;
                  border:1px solid #222;
                }
              }
              textarea{
                height:300px;
                padding-top:14px;
                line-height:32px;
                resize: vertical;
              }
              .label_div{
                //font-size:0;
                //letter-spacing:normal;
                display:table;
                width:100%;
                position:relative;
                table-layout: fixed;
                &+.label_div{
                  margin-top:10px;
                }
                input[type="file"]{
                  display:none;
                }
                .mail_label{
                  width:117px;
                  height:30px;
                  border:1px solid #222;
                  font-size:14px;
                  font-weight:700;
                  line-height:30px;
                  text-align:center;
                  vertical-align:middle;
                  display:table-cell;
                }
                .statusBox{
                  vertical-align:middle;
                  width:100%;
                  padding:0 95px 0 5px;
                  overflow:hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-wrap: break-word;
                  font-size:16px;
                  display:table-cell;
                  &#text10_status{
                    padding-right:25px;
                  }
                }
                .reset_button{
                  //display:inline-block;
                  vertical-align:middle;
                  width:74px;
                  height:26px;
                  font-size:12px;
                  font-weight:700;
                  border-radius:20px!important;
                  background-image:url(../img/mark_clear.png);
                  background-repeat:no-repeat;
                  background-position:center left 14px;
                  background-size:10px auto;
                  padding-left:20px;
                  //display:table-cell;
                  position:absolute;
                  top:0;
                  right:0;
                }
                &+span.note{
                  margin-top:5px;
                }
              }
              .radio{
                width: 30px;
                height: 30px;
                margin-top:10px;
                margin-right: 10px;
                &#uniform-text10_1{
                  margin-top:8px;
                }
                span{
                  width: 30px;
                  height: 30px;
                  background: transparent url(../img/btn_radio01.png) no-repeat scroll left center;
                  background-size:30px 30px;
                  &.checked{
                    background: transparent url(../img/btn_radio02.png) no-repeat scroll left center;
                    background-size:30px 30px;
                  }
                  input{
                    width:30px;
                    height:30px;
                    vertical-align:top;
                  }
                }
              }
              label{
                display:inline-block;
                vertical-align:bottom;
                line-height:30px;
              }
              span.note{
                color:#e75f4d;
                font-size:14px;
                line-height:24px;
                display:block;
                margin-top:10px;
                padding-left:1.5em;
                text-indent:-1.5em;
              }
            }
          }
        }
        ul.contact-form1{
          position: relative;
          width:100%;
          margin:60px 0 115px;
          li{
            &.reset{
              text-align:center;
              margin-top:25px;
              input[type="button"]{
                color: #888;
                font-size: 14px;
                text-decoration: underline;
              }
            }
            #image-btn{
              display: block;
              width: 255px;
              height: 60px;
              text-align:center;
              margin: 0px auto;
              background-color: #222;
              background-image:url(../img/arrow08.png);
              background-repeat:no-repeat;
              background-position:center right 15px;
              background-size:18px auto;
              opacity: 1;
              color: #fff;
              font-size: 16px;
              font-weight:700;
              text-decoration: none;
              line-height: 60px;
              border-radius: 0 !important;
            }
          }
        }
      }
    }
  }

  /*
  確認画面 共通
  -------------------------------------*/
  #PageInquiry.pageConfirm,
  #PageMail_kaitori.pageConfirm,
  #PageCustomer_info.pageConfirm{
    #Main{
      form{
        dl{
          &+dl{
            margin-top:35px;
          }
          dd:last-of-type{
            line-height:32px;
            span.note{
              margin-top:0;
            }
          }
        }
      }
    }
  }

  /*
  完了画面 共通
  -------------------------------------*/
  #PageInquiry.pageThanks,
  #PageMail_kaitori.pageThanks,
  #PageCustomer_info.pageThanks {
    .innerBasic{
      padding:0;
    }
    #Main{
      #ContBox01{
        padding:0 15px;
        >p{
          font-size:16px;
          line-height:32px;
        }
        >dl{
          margin:50px auto 60px;
          border-top:1px solid #dcdcdc;
          border-bottom:1px solid #dcdcdc;
          text-align:center;
          padding: 27px 0 16px;
          dt{
            font-size:14px;
            font-weight:700;
          }
          dd{
            color:#888;
            font-size:12px;
            line-height:1;
            letter-spacing: 2.5px;
            margin:3px 0 12px;
            &:first-of-type{
              font-family: 'Lato', sans-serif;
              font-weight: 400;
              font-size:20px;
              color:#222;
              letter-spacing:4px;
              a{
                color:#222;
                text-decoration:underline;
              }
            }
          }
        }
        .linkBtn{
          width:255px;
          height:50px;
          line-height:50px;
          margin:60px auto 0;
        }
        .linkTxt{
          font-size:14px;
          text-align:center;
          a{
            color:#888;
            text-decoration:underline;
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageInquiry.pageIndex {
    #Main{
      form{
        dl{
          &:nth-of-type(n+7){
            margin-top:40px;
          }
        }
      }
    }
  }

  /*
  確認画面（お問い合わせ）
  -------------------------------------*/
  #PageInquiry.pageConfirm {
  }

  /*
  完了画面（お問い合わせ）
  -------------------------------------*/
  #PageInquiry.pageThanks {
  }

  /*
  メール査定フォーム
  -------------------------------------*/
  #PageMail_kaitori.pageIndex {
    sup{
      color:#888;
    }
  }

  /*
  確認画面（メール査定フォーム）
  -------------------------------------*/
  #PageMail_kaitori.pageConfirm {
  }

  /*
  完了画面（メール査定フォーム）
  -------------------------------------*/
  #PageMail_kaitori.pageThanks {
  }

  /*
  LINE査定について,宅配買取について,出張買取について
  共通
  -------------------------------------*/
  #PageLine.pageIndex,
  #PageTakuhai_kaitori.pageIndex,
  #PageHome_kaitori.pageIndex{
    #Main{
      ol.flow{
        width:100%;
        list-style:none;
        li{
          padding-top:30px;
          padding-bottom:30px;
          &+li{
            border-top: 1px solid #dcdcdc;
          }
          >dl{
            >dd{
              &:first-of-type{
                font-family: 'Lato', sans-serif;
                font-weight: 700;
                text-align:center;
                font-size:14px;
                height:30px;
                &:after{
                  //content:"";
                  display:inline-block;
                  vertical-align:bottom;
                  width:26px;
                  height:30px;
                  margin-left:5px;
                  //background-image:url(../img/icon_01.png);
                  background-size:26px auto;
                  background-position:center top;
                  background-repeat:no-repeat;
                }
                span{
                  //display:none;
                  font-family: 'Oswald', sans-serif;
                  font-weight: 700;
                  font-size:32px;
                  line-height:1;
                  padding-left:5px;
                }
              }
              &:nth-of-type(2){
                font-size:16px;
                line-height:32px;
              }
              figure{
                img{
                  width:100%;
                  height:auto;
                }
              }
            }
            >dt{
              font-size:16px;
              font-weight:700;
              display:table;
              border-bottom:3px solid #222;
              padding:0 3px 5px;
              margin:0 auto;
              margin-top:22px;
            }
          }
          &:nth-child(2){
            >dl{
              >dd{
                &:first-of-type{
                  &:after{
                    width:32px;
                    background-image:url(../img/icon_02.png);
                    background-size:32px auto;
                  }
                }
              }
            }
          }
          &:nth-child(3){
            >dl{
              >dd{
                &:first-of-type{
                  &:after{
                    width:33px;
                    background-image:url(../img/icon_03.png);
                    background-size:33px auto;
                  }
                }
              }
            }
          }
          &:nth-child(4){
            >dl{
              >dd{
                &:first-of-type{
                  &:after{
                    width:33px;
                    background-image:url(../img/icon_04.png);
                    background-size:33px auto;
                  }
                }
              }
            }
          }
        }
      }
      .grayBox{
        background-color:#eeeeee;
        width:100%;
        text-align:center;
        padding:0 30px 25px;
        dt{
          background-color:#222;
          font-size:16px;
          font-weight:700;
          color:#fff;
          height:40px;
          line-height:40px;
          padding:0 20px;
          display:inline-block;
          margin-bottom:25px;
        }
      }
    }
  }

  /*
  LINE査定について
  -------------------------------------*/
  #PageLine.pageIndex {
    #Container {
      #Main {
        .innerBasic {
          h3 {
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-family: 'Lato', sans-serif;
            line-height: 41px;
          }
          p {
            font-size: 18px;
            color: #888888;
            font-weight: 700;
            text-align: center;
            letter-spacing: 3px;
            margin-top: 8px;
          }
          section.contSubBox.add_friend {
              width: 100%;
              height: auto;
              padding-bottom: 34px;
              background-color: #eeeeee;
              text-align: center;
              margin-top: 61px !important;
              margin-bottom: 75px;
              >dl {
                font-size: 16px;
                text-align: center;
                dt {
                  width: 148px;
                  height: 40px;
                  margin: 0 auto;
                  font-size: 16px;
                  line-height: 40px;
                  color: #ffffff;
                  letter-spacing: 1px;
                  // text-align: center;
                  background-color: #222222;
                }
                dd {
                  font-size: 16px;
                  margin-top: 25px;
                  letter-spacing: 1.4px;
                  padding-left: 30px;
                  padding-right: 30px;
                  //padding: 30px;
                  text-align: left;
                  line-height: 27px;
                  &:nth-of-type(2) {
                    display: inline-block;
                    width: 70px;
                    height: 70px;
                    background-color: #ffffff;
                    padding: 6px;
                    img {
                      width: 58px;
                      height: 58px;
                    }
                  }
                  &:nth-of-type(3) {
                    display: inline-block;
                    font-size: 18px;
                    position: relative;
                    top: -18px;
                    margin-left: 11px;
                    letter-spacing: 2px;
                    font-weight: 700;
                    font-family: 'Lato', sans-serif;
                    padding: 0;
                    margin-left: 10px;
                    h4 {

                    }
                  }
                }
              }
          }
        }
        #ContBox01 {
          border-bottom: 1px solid #dcdcdc;
          margin-top: 1px;
          .innerBasic {
            section.contSubBox.add_friend {
              margin-top: 0 !important;
            }
            .contSubBox {
              margin-top: 57px;
              .desc_Line {
                width: 100%;
                position: relative;
                border-bottom: 1px solid #dcdcdc;
                display: table;
                padding-bottom: 25px;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 11px 6px 0 6px;
                  border-color: #222222 transparent transparent transparent;
                  position: absolute;
                  // top: 31px;
                  right: 0;
                  left: 0;
                  margin: 0 auto;
                  bottom: -8px;
                }
                &:nth-of-type(2) {
                  margin-top: 30px;
                  padding-bottom: 27px;
                  dl {
                    dt {
                    }
                    dd {
                      &:first-of-type {
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          bottom: -11px;
                          left: -4px;
                          display: block;
                          width: 76px;
                          height: 3px;
                          background: #222;
                        }
                      }
                    }
                  }
                  figure {
                    img {

                    }
                  }
                }
                &:nth-of-type(3) {
                  margin-top: 30px;
                  padding-bottom: 26px;
                  >dl {
                    >dt {

                    }
                    >dd {
                      &:first-of-type {
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          bottom: -11px;
                          left: -4px;
                          display: block;
                          margin: auto;
                          width: 103px;
                          height: 3px;
                          background: #222;
                        }
                      }
                      &:nth-of-type(2) {
                        span {
                          font-weight: 700;
                        }
                      }
                    }
                  }
                  figure {
                    img {

                    }
                  }
                }
                &:last-of-type {
                  border: none;
                  margin-top: 30px;
                  margin-bottom: 30px;
                  &:after {
                    display:none;
                  }
                  dl {
                    dt {

                    }
                    dd {
                      &:first-of-type {
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          bottom: -11px;
                          left: -4px;
                          display: block;
                          width: 110px;
                          height: 3px;
                          background: #222;
                        }
                      }
                    }
                  }
                  figure {
                    img {

                    }
                  }
                }
                >dl {
                  display: table-cell;
                  vertical-align: top;
                  dt {
                    text-transform: uppercase;
                    font-family: 'Lato', sans-serif;
                    font-weight: 700;
                    margin-top: -7px;
                    span {
                      font-size: 32px;
                      font-family: 'Oswald', sans-serif;
                    }
                  }
                  dd {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    span {

                    }
                    &:first-of-type {
                      position: relative;
                      margin-top: 18px;
                      margin-left: 5px;
                      letter-spacing: 1px;
                      &:after {
                        content: "";
                        position: absolute;
                        bottom: -11px;
                        left: -4px;
                        display: block;
                        width: 93px;
                        height: 3px;
                        background: #222;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 37px;
                      font-weight: 500;
                      line-height: 27px;
                    }
                  }
                }
                figure {
                  display: table-cell;
                  vertical-align: top;
                  width: 165px;
                  text-align: center;
                  img {
                    width: 128px;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          margin-top: 67px;
          border-bottom: 1px solid #dcdcdc;
          .innerBasic {

            h3  {
              font-family: 'Lato', sans-serif;
            }
            p {
            }
            .contSubBox {
              margin-top: 57px;
              margin-bottom: 74px;
              dl {
                position: relative;
                font-size: 16px;
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -13px;
                  display: block;
                  margin: auto;
                  width: 100%;
                  height: 1px;
                  background: #dcdcdc;
                }
                dt {
                  font-weight: 700;
                  line-height: 16px;
                  letter-spacing: 1px;
                }
                dd {
                  line-height: 32px;
                  margin-top: 8px;
                  letter-spacing: 0.8px;
                  padding-left: 25px;
                }
                &:first-of-type {
                  dd {

                  }
                }
                &:nth-of-type(2) {
                  margin-top: 32px;
                  &:after {
                    bottom: -16px;
                  }
                  dt {
                    line-height: 32px;
                    padding-left: 1.8em;
                    text-indent: -1.8em;
                    letter-spacing: 1px;
                  }
                }
                &:nth-of-type(3) {
                  margin-top: 39px;
                  &:after {
                    display:none;
                  }
                  dt {
                    line-height: 16px;
                  }
                }
              }
            }
          }
        }
        #ContBox03 {
          margin-top: -5px;
          .innerBasic {
            margin-top: 72px;
            margin-bottom: 76px;
            section.contSubBox.add_friend {
              margin-top: 54px !important;
              margin-bottom: 80px;
            }
            h3 {
              font-family: 'Lato', sans-serif;
              span {
                display: block;
              }
            }
            p {
              &:nth-of-type(2) {
                margin-top: 50px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.5px;
                font-weight: 500;
                text-align: left;
                color: #222;
                span {
                  display: inline;
                }
              }
              span {
                display: block;
              }
            }
            .contSubBox02 {
              margin: 31px -15px 0;
              >dl {
                max-width: 330px;
                width: 100%;
                text-align: center;
                margin: 0 auto;
                overflow: hidden;
                dt {
                  font-size: 14px;
                  font-family: 'Lato', sans-serif;
                  font-weight: 700;
                  text-transform: uppercase;
                  text-align: center;
                  letter-spacing: 1px;
                  padding-left: 8px;
                  span {
                    font-size: 32px;
                    font-weight: 700;
                    font-family: 'Oswald', sans-serif;
                  }
                }
                dd {

                  &:first-of-type {
                    position: relative;
                    margin-top: 15px;
                    text-align: center;
                    margin-bottom: 66px;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    &:after {
                      content: "";
                      position: absolute;
                      right: 0;
                      bottom: -7px;
                      left: 0;
                      display: block;
                      margin: auto;
                      width: 161px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(2) {
                    display: none;
                    // position: relative;
                    // display: inline-block;
                    // margin-top: 40px;
                    // margin-left: 3px;
                    img {
                      width: 180px;
                    }
                    &:after {
                      content: "";
                      position: absolute;
                      right: 0;
                      bottom: -32px;
                      left: 3px;
                      display: block;
                      margin: auto;
                      width: 60px;
                      height: 1px;
                      background: #dcdcdc;
                    }

                  }
                  &:nth-of-type(3) {
                    width: 100px;
                    margin: 0 0 0 auto;  //右寄せ
                    display: block;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #e75f4d;
                    position: relative;
                    text-align: center;
                    padding-top: 5px;
                    font-family: 'Oswald', sans-serif;
                    &:before {
                      content: "";
                      display: inline-block;
                      width: 23px;
                      height: 23px;
                      background: url(../img/icon_11.png) no-repeat;
                      background-position: center top;
                      background-size: 23px auto;
                      position: absolute;
                      top: -25px;
                      right: 37px;
                    }
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 180px;
                      height: 180px;
                      background: url(../img/img_item07.png) no-repeat;
                      background-position: left top;
                      background-size: 180px auto;
                      position: absolute;
                      top: -28px;
                      left: -230px;
                    }
                  }
                  &:nth-of-type(4) {
                    width: 100px;
                    margin: 0 0 0 auto;
                    display: block;
                    margin-top: 8px;
                    position: relative;
                    left: -4px;
                    img {
                      width: 90px;
                    }
                    &:before{
                      content:"";
                      display:block;
                      position:absolute;
                      width:1px;
                      height:85px;
                      background:#dcdcdc;
                      left:-25px;
                      top:-18px;
                    }
                  }
                  &:nth-of-type(5) {
                    display: block;
                    width: 100px;
                    margin: 0 0 0 auto;
                    margin-top: 12px;
                    //margin-left: 229px;
                    font-size: 16px;
                  }
                }
                &:nth-of-type(2) {
                  max-width: 330px;
                  width: 100%;
                  text-align: center;
                  margin: 47px auto 42px auto;
                  overflow: hidden;
                  dt {

                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 220px;
                        left: -2px;
                      }
                    }
                    &:nth-of-type(3) {
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 180px;
                        height: 180px;
                        background: url(../img/img_item09.png) no-repeat;
                        background-position: left top;
                        background-size: 180px auto;
                        position: absolute;
                        top: -27px;
                        left: -230px;
                      }
                    }
                    &:nth-of-type(5) {
                      left: -5px;
                      line-height: 28px;
                      text-align: center;
                    }
                  }
                }
                &:nth-of-type(3) {
                  max-width: 330px;
                  width: 100%;
                  text-align: center;
                  margin: 0 auto 0 auto;
                  overflow: hidden;
                  dt {
                    padding-left: 0;
                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 201px;
                      }
                    }
                    &:nth-of-type(3) {
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 180px;
                        height: 180px;
                        background: url(../img/img_item11.png) no-repeat;
                        background-position: left top;
                        background-size: 180px auto;
                        position: absolute;
                        top: -28px;
                        left: -230px;
                      }
                    }
                    &:last-of-type {
                      top: 12px;
                      left: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  宅配買取について
  -------------------------------------*/
  #PageTakuhai_kaitori.pageIndex {
    #Container {
      #Main {
        #ContBox01 {
          margin-top: -6px;
          margin-bottom: 64px;
          .innerBasic {
            h3 {
              font-size: 40px;
              color: #000000;
              font-weight: 700;
              text-align: center;
            }
            #ContSubBox01 {
              h4 {
                font-size: 36px;
                font-weight: 700;
                font-family: 'Lato', sans-serif;
                text-align: center;
                letter-spacing: 2px;
                margin-bottom: 4px;
                span {
                  display: block;
                  margin-top: -14px;
                }
              }
              p {
                font-size: 18px;
                color: #888888;
                font-weight: 700;
                text-align: center;
                letter-spacing: 2px;
                &:nth-of-type(2) {
                  color: #222222;
                  font-size: 20px;
                  line-height: 33px;
                  margin-top: 48px;
                  text-align: left;
                }
                span {

                }
              }
              >dl {  //OKのdl
                text-align: center;
                margin-top: 35px;
                border-bottom: 1px solid #dcdcdc;
                padding-bottom: 23px;
                dt {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  font-family: 'Lato', sans-serif;
                  margin-left: 7px;
                  letter-spacing: 1px;
                  span {
                    font-size: 32px;
                    line-height: 32px;
                    margin-top: 10px;
                    font-family: 'Oswald', sans-serif;
                    font-weight: 700;
                    //oswald
                  }
                }
                dd {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: 700;
                  letter-spacing: 1px;
                  position: relative;
                  span {
                    display: block;
                  }
                  &:first-of-type {
                    margin-top: 28px;
                    &:after {
                      content: "";
                      position: absolute;
                      bottom: -10px;
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                      display: block;
                      width: 191px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(2) {
                    margin-top: 41px;
                    img {
                      width: 56px;
                    }
                  }
                  &:nth-of-type(3) {
                    font-size: 16px;
                    font-weight: 500;
                    margin-top:22px;
                    line-height: 30px;
                    letter-spacing: 1px;
                    text-align: left;
                    span {
                      display: inline;
                    }
                  }
                }
                &:nth-of-type(2) {  //NGのdl
                  border-bottom: none;
                  padding-bottom: 0;
                  margin-bottom: -13px;
                  dt {
                    margin-top: -5px;
                  }
                  dd {
                    &:first-of-type {
                      &:after {
                        width: 161px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 39px;
                      margin-left: 17px;
                      img {
                        width: 71px;
                      }
                    }
                    &:nth-of-type(3) {
                      margin-top: 23px;
                      letter-spacing: .8px;
                    }
                  }
                }
              }
              .adress {
                margin-top: 64px;
                font-size: 16px;
                dl {
                  background-color: #eeeeee;
                  text-align: center;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-bottom: 26px;
                  dt {
                    width: 80px;
                    height: 40px;
                    background-color: #222222;
                    margin: 0 auto;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 40px;
                    text-align: center;
                  }
                  dd {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    &:first-of-type {
                      margin-top: 29px;
                      span {
                        margin-left: 7px;
                        margin-top: 16px;
                        display: block;
                      }
                    }
                    &:nth-of-type(2) {
                      margin-top: 16px;
                    }
                    &:nth-of-type(3) {
                      margin-top: 16px;
                    }
                    &:nth-of-type(4) {
                      font-size: 14px;
                      line-height: 22px;
                      font-weight: 500;
                      color: #888888;
                      padding-left:1em;
                      text-indent:-1em;
                      letter-spacing: 1px;
                      text-align: left;
                      margin-top: 26px;
                    }
                    &:nth-of-type(5) {
                      font-size: 14px;
                      line-height: 22px;
                      font-weight: 500;
                      color: #888888;
                      margin-top: 10px;
                      text-align: left;
                      padding-left:1em;
                      text-indent:-1em;
                      letter-spacing: 1px;
                    }
                  }
                }
                p {
                  font-size: 16px;
                  line-height: 32px;
                  color: #222222;
                  margin-top: 24px;
                  margin-bottom: 0;
                  letter-spacing: 1px;
                  font-weight: 500;
                  text-align: left;
                  a {
                    color: #888888;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          padding-top: 35px;
          border-top: 1px solid #dcdcdc;
          #ContSubBox02 {
            .innerBasic {
              h3 {
                font-size: 36px;
                font-weight: 700;
                font-family: 'Lato', sans-serif;
                text-align: center;
                margin-top: 25px;
                letter-spacing: 2.5px;
              }
              p {
                font-size: 18px;
                color: #888888;
                font-weight: 700;
                text-align: center;
                &:first-of-type {
                  letter-spacing: 3px;
                }
                &:nth-of-type(2) {
                  margin-top: 54px;
                  font-size: 20px;
                  line-height: 32px;
                  color: #222222;
                  letter-spacing: 1.6px;
                  text-align: left;
                  span {
                    display: inline;
                    margin-top: 16px;
                  }
                }
              }
              dl {
                margin-top: 43px;
                padding-top: 30px;
                padding-bottom: 25px;
                border-bottom: 1px solid #dcdcdc;
                dt {
                  font-size: 24px;
                  color: #222222;
                  font-weight:700;
                  text-align: center;
                  margin-top: 7px;
                  letter-spacing: 2px;
                }
                dd {
                  font-size: 16px;
                  line-height: 32px;
                  margin-top: 16px;
                  text-align: left;
                  span {

                  }
                }
                &:first-of-type {
                  border-top: 1px solid #dcdcdc;
                  margin-top: 54px;
                  padding-bottom: 35px;
                }
                &:nth-of-type(2) {
                  margin-top: 0;
                  padding-bottom: 36px;
                }
              }
            }
          }
          #ContSubBox03 {
            margin-top: 60px;
            >dl {
              margin: 60px auto 0 auto;
              >dt {
                font-size: 24px;
                line-height: 24px;
                font-weight: 700;
                margin-left: 5px;
                text-align: center;
                letter-spacing: 2px;
                span {
                  display: block;
                  margin-top: 11px;
                }
              }
              >dd {
                background-color: #eeeeee;
                padding: 15px 15px 20px 15px;
                font-size: 0;
                letter-spacing: normal;
                margin-top: 28px;
                >dl {
                  height: 90px;
                  background-color: #ffffff;
                  margin-bottom: 10px;
                  margin-top: 15px;
                  padding-top: 19px;
                  padding-left: 31px;
                  >dd {
                    background-color: #ffffff;
                    width: 84px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 32px;
                    img {
                      width: 55px;
                    }
                  }
                  >dt {
                    vertical-align: middle;
                    width: 160px;
                    font-size: 16px;
                    font-weight: 500;
                    display: inline-block;
                    position: relative;
                    &:before {
                      content: "";
                      display: inline-block;
                      width: 21px;
                      height: 21px;
                      background: url(../img/mark_check.png) no-repeat;
                      background-position: left center;
                      background-size: 21px auto;
                      position: absolute;
                      top: 0;
                      left: -30px;
                    }
                  }
                  &:nth-of-type(2) {
                    margin-top: -5px;
                    padding-top: 22px;
                    padding-left: 34px;
                    >dd {
                      margin-right: 28px;
                      img {
                      width: 47px;
                      }
                    }
                    >dt {
                      span {
                        font-size: 14px;
                        color: #888888;
                        margin-left: -31px;
                        letter-spacing: 1px;
                        display: block;
                        padding-top: 5px;
                      }
                      &:before {
                        top: 0;
                        left: -30px;
                      }
                    }
                  }
                  &:nth-of-type(3) {
                    margin-top: -5px;
                    padding-top: 19px;
                    padding-left: 34px;
                    >dd {
                      margin-right: 29px;
                      img {
                      width: 44px;
                      }
                    }
                    >dt {
                      &:before {
                        top: 0;
                        left: -31px;
                      }
                    }
                  }
                }
              }
            }
          }
          #ContSubBox04 {
            margin-top: 30px;
            margin-bottom: 116px;
            .innerBasic {
              h4 {
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                position: relative;
                margin-top: 132px;
                letter-spacing: 2px;
                &:before {
                  content: "";
                  display: inline-block;
                  width: 55px;
                  height: 54px;
                  background: url(../img/mark_box02.png) no-repeat;
                  background-position: center top;
                  background-size: 55px auto;
                  position: absolute;
                  top: -70px;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                }
              }
              p {
                text-align: left;
                margin-top: 14px;
                line-height: 32px;
                letter-spacing: 1.5px;
                span {
                  display: inline;
                }
              }
              >dl {
                margin-top: 31px;
                text-align: center;
                position: relative;
                border-bottom: 1px solid #dcdcdc;
                padding-bottom: 22px;
                >dt {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  font-family: 'Lato', sans-serif;
                  margin-top: 25px;
                  letter-spacing: 1px;
                  display: inline-block;
                  font-family: 'Lato', sans-serif;
                  //lato
                }
                >dd {
                  &:first-of-type {
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 700;
                    margin-top: 7px;
                    display: inline-block;
                    font-weight: 700;
                    font-family: 'Oswald', sans-serif;
                    //oswald
                  }
                  &:nth-of-type(2) {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-top: 24px;
                    letter-spacing: 2px;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      bottom: -12px;
                      left: 0;
                      right:0;
                      margin:auto;
                      display: block;
                      width: 181px;
                      height: 3px;
                      background: #222;
                    }
                  }
                  &:nth-of-type(3) {
                    height: 210px;
                    position: relative;
                    margin-top: 11px;
                    img {
                      width: 286px;
                      height: auto;
                      position: absolute;
                      margin: auto;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                    }
                  }
                  &:nth-of-type(4) {
                    font-size: 14px;
                    line-height: 30px;
                    color: #ffffff;
                    font-weight: 700;
                    background-color: #b9b9b9;
                    height: 30px;
                    margin: 0 auto;
                    width: 125px;
                  }
                  &:nth-of-type(5) {
                    font-size: 16px;
                    line-height: 30px;
                    margin-top: 10px;
                    letter-spacing: 1px;
                  }
                }
                &:nth-of-type(2) {
                  border-right: none;
                  padding-right: 0;
                  padding-bottom: 20px;
                  margin-top: 22px;
                  &:after {
                    left: 14px;
                  }
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      &:after {
                        bottom: -12px;

                      }
                    }
                    &:nth-of-type(3) {
                      height: 180px;
                      img {
                        width: 268px;
                      }
                    }
                    &:nth-of-type(4) {
                    }
                    &:nth-of-type(5) {
                      letter-spacing: .8px;
                    }
                  }
                }
                &:nth-of-type(3) {
                  margin-top: 24px;
                  >dt {
                    margin-top: 19px;
                  }
                  >dd {
                    &:nth-of-type(2) {

                    }
                    &:nth-of-type(3) {
                      height: 180px;
                      img {
                        width: 264px;
                      }
                    }
                    &:nth-of-type(4) {

                    }
                    &:nth-of-type(5) {
                      letter-spacing: .8px;
                    }
                  }
                }
                &:nth-of-type(4) {
                  border: none;
                  padding-right: 0;
                  &:after {
                    left: 14px;
                  }
                  >dt {
                    margin-top: 0;
                  }
                  >dd {
                    margin-top: 0px;
                    &:nth-of-type(2) {
                      margin-top: 23px;
                    }
                    &:nth-of-type(3) {
                      height: 150px;
                      margin-top: 12px;
                      img {
                        width: 214px;
                      }
                    }
                    &:nth-of-type(4) {

                    }
                    &:nth-of-type(5) {
                      margin-top: 10px;
                    }
                  }
                }
              }
              ul {
                li {
                  width: 254px;
                  height: 88px;
                  border: 3px solid #222;
                  margin: 14px auto 0 auto;
                  a {
                    height: 88px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    display: block;
                    text-decoration: none;
                    position: relative;
                    letter-spacing: 1.5px;
                    padding-top: 19px;
                    span {
                      display: block;
                      padding-top: 12px;
                    }
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 9px;
                      height: 15px;
                      background: url(../img/arrow02.png) no-repeat;
                      background-position: right;
                      background-size: 9px auto;
                      position: absolute;
                      top: 33px;
                      right: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  出張買取について
  -------------------------------------*/
  #PageHome_kaitori.pageIndex {
    #Main{
      margin-bottom:170px;
      #ContBox01{
        >p{
          font-size:20px;
          font-weight:700;
          line-height:32px;
          color:#222;
          margin:45px 0 10px;
        }
      }
      ol.flow{
        li{
          >dl{
            >dt{
              margin-bottom:30px;
            }
            >dd{
              &:nth-of-type(2){
                padding-top:78px;
                background-image:url(../img/mark_mail02.png);
                background-size:51px auto;
                background-position:center 10px;
                background-repeat:no-repeat;
                span{
                  display:block;
                  font-size:14px;
                  line-height:24px;
                  color:#888;
                  padding-left:1.4em;
                  text-indent:-1.4em;
                  margin-top:17px;
                }
              }
              figure{
                margin:20px auto 0;
                width:210px;
              }
              dl{
                margin-top:18px;
                text-align:center;
                &:before{
                  content:"";
                  display:block;
                  width:165px;
                  height:1px;
                  background:#dcdcdc;
                  margin: 0 auto;
                }
                dt{
                  padding-top:20px;
                  font-size:18px;
                  font-family: 'Lato', sans-serif;
                  letter-spacing: 3.5px;
                  a{
                    color:#222;
                  }
                }
                dd{
                  font-size:12px;
                  color:#888;
                  letter-spacing: 2.5px;
                  line-height: 1;
                  margin-top: 7px;
                }
              }
              .linkBtn{
                margin:28px auto 0;
                width:255px;
                height:50px;
                line-height:50px;
              }
            }
          }
          &:nth-child(2){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_calendar.png);
                  background-size:49px auto;
                  background-position:center 6px;
                }
              }
            }
          }
          &:nth-child(3){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_car.png);
                  background-size:63px auto;
                  background-position:center 10px;
                }
              }
            }
          }
          &:nth-child(4){
            >dl{
              >dd{
                &:nth-of-type(2){
                  background-image:url(../img/mark_money.png);
                  background-size:56px auto;
                  background-position:center top;
                }
              }
            }
          }
        }
      }
      .grayBox{
        margin-top:10px;
        dd{
          .fontB{
            font-size:20px;
            font-weight:700;
          }
          .note{
            font-size:14px;
            line-height:24px;
            color:#888;
            display:block;
            margin-top:18px;
            text-align:left;
            padding-left:1.4em;
            text-indent:-1.4em;
          }
        }
      }
    }
  }

  /*
  運営会社
  -------------------------------------*/
  #PageCompany.pageIndex {
    .innerBasic {
      padding: 0;
      #Main{
        margin-bottom: 88px;
        dl{
          font-size:16px;
          line-height: 32px;
          color:#222;
          border-top:1px solid #dcdcdc;
          width:100%;
          display: table;
          padding:23px 15px 20px;
          &:last-of-type{
            border-bottom:1px solid #dcdcdc;
          }
          dt,dd{
          }
          dt{
            font-weight:700;
          }
          a{
            color:#888;
            text-decoration:underline;
            &.tel{
              color:#222;
            }
          }
        }
      }
    }
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePrivacy.pageIndex {
    #Main{
      p,li,dt,dd{
        font-size:16px;
        line-height:32px;
        text-align: justify;
        text-justify: inter-ideograph;
      }
      h3{
        font-size:30px;
        line-height:38px;
        letter-spacing:5px;
        font-weight:700;
        color:#000;
        text-align:center;
        margin-bottom:60px;
        display: none;
      }
      .contBox{
        padding:0 15px;
        padding-top:　0;
        .contSubBox{
          margin-top:50px;
          border-top:1px solid #dcdcdc;
          padding-bottom:80px;
          h4{
            font-size:24px;
            line-height:35px;
            font-weight:700;
            margin-top:45px;
            margin-bottom:30px;
            letter-spacing:2px;
          }
          p{
            margin-bottom:0;
            &+p{
              margin-top:32px;
            }
          }
        }
      }
      #ContBox01{
        .contSubBox{
          >dl{
            margin-top:34px;
            dd{
              dl{
                display:table;
                width:100%;
                dt,dd{
                  display:table-cell;
                  vertical-align:top;
                }
                dt{
                  width:80px;
                  position:relative;
                  &:after{
                    content:"：";
                    position:absolute;
                    top:0;
                    right:0;
                  }
                }
                dd{
                  padding-left:0.5em;
                  a{
                    color:#222;
                    text-decoration:underline;
                  }
                }
              }
            }
          }
        }
      }
      #ContBox02{
        border-top:1px solid #dcdcdc;
        .contSubBox{
          >ol{
            counter-reset: item;
            list-style-type: none;
            padding-left: 0;
            >li{
              position:relative;
              &:before{
                counter-increment: item;
                content: counter(item)'.';
                position:absolute;
                top:0;
                left:0;
              }
              padding-left:1.2em;
              &+li{
                margin-top:5px;
              }
              dl{
                margin-top: 5px;
                dt{
                  font-weight:700;
                }
              }
              ol{
                list-style: none;
                &.note{
                  margin-top: 10px;
                  li{
                    font-size:14px;
                    line-height:24px;
                    color:#888;
                    ul{
                      li{
                        text-indent:0;
                      }
                    }
                  }
                  >li{
                    position:relative;
                    padding-left:2.2em;
                    text-indent:-2.2em;
                    span{
                      padding-right: 0.5em;
                    }
                    &+li{
                      margin-top:15px;
                    }
                  }
                }
              }
            }
          }
          .link{
            margin-top:8px;
            &:before{
              content:"＞";
              color:#888;
              margin-right:6px;
            }
            a{
              color:#888;
              text-decoration:underline;
            }
          }
        }
      }
    }
  }

  /*
  お客様情報送信フォーム
  -------------------------------------*/
  #PageCustomer_info {
    #Header{
      #ContBoxHeader{
        #MenuButton{
          display:none;
        }
      }
    }
    #HeaderMenu_sp,#GlobalNav,#TopicPath{
      display:none;
    }
    #Footer .footerMain dl,#PageTop{
      display:none;
    }
    #Main{
      #ContBox01{
        .link{
          display:table;
          padding-right:25px;
          background:url(../img/mark_google.png) no-repeat top 3px right;
          background-size:11px auto;
        }
      }
    }
  }

  /*
  確認画面（お客様情報送信フォーム）
  -------------------------------------*/
  #PageCustomer_info.pageConfirm {
  }

  /*
  完了画面（お客様情報送信フォーム）
  -------------------------------------*/
  #PageCustomer_info.pageThanks {
  }


}

/*#################### IE ####################*/
@media screen and (min-width: 768px) and (-ms-high-contrast: active), print and (-ms-high-contrast: none), screen and (min-width: 768px) and (-ms-high-contrast: none) {
  .pageIndex#Page #Container #Main #ContBox01 #TopImg .innerBasic dl dd.serch_dd form label div{
    padding-top: 5px;
  }
  .pageIndex#Page #Container #Main #ContBox03 .innerBasic > ul > li > a > dl > dt{
    padding-top: 2px;
  }
  .pageIndex#Page #Container #Main #ContBox04 .customerWrap .innerbasic > div > dl dt:first-of-type{
    padding-top: 3px;
  }
  .pageIndex#Page #Container #Main #ContBox04 .shopWrap > div .innerbasic h4{
    padding-top: 2px;
  }
  .pageIndex#Page #Container #Main #ContBox04 .shopWrap > div .innerbasic > div > dl > dd:nth-of-type(3) a span{
    padding-top: 3px;

  }
  .pageIndex#PageTakuhai_kaitori #Container #Main #ContBox01 .innerBasic #ContSubBox01 .adress dl dt{
    padding-top: 3px;
  }
  .pageIndex#PageTakuhai_kaitori #Container #Main #ContBox02 #ContSubBox04 .innerBasic > dl > dd:nth-of-type(4){
    padding-top: 2px;
  }
  .pageIndex#PageLine #Main .grayBox dt, .pageIndex#PageTakuhai_kaitori #Main .grayBox dt, .pageIndex#PageHome_kaitori #Main .grayBox dt{
    padding-top: 3px;
  }
  .pageIndex#PageInquiry #Main form dl dd:first-child span, .pageIndex#PageMail_kaitori #Main form dl dd:first-child span, .pageIndex#PageCustomer_info #Main form dl dd:first-child span, .pageConfirm#PageInquiry #Main form dl dd:first-child span, .pageConfirm#PageMail_kaitori #Main form dl dd:first-child span, .pageConfirm#PageCustomer_info #Main form dl dd:first-child span{
    padding-top: 2px;
  }
}

@media screen and (min-width:768px) and ( max-width:1000px) {
#Page.pageIndex #Container #Main #ContBox03 .innerBasic> dl> dd div:first-of-type{
        padding-left: 5px;
        width: 280px;
        font-size: 14px;
}
#Page.pageIndex #Container #Main #ContBox03 .innerBasic> dl> dd div:nth-of-type(2) span {
        font-size: 20px;
  }
}